import { Home } from './page/Home'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { LessonList } from './page/lesson/LessonList'
import { LessonContent } from './page/lesson/Lesson'
import { Navbar } from './component/Navbar'
import { Profile } from './page/profile/Profile'
import { Dictionary } from './page/Dictionary'
import { Exercise } from './page/Exercise'
import { Dialog } from './page/Dialog'
import { Login } from './page/Login'
import { Register } from './page/Register'
import { Activation } from './page/Activation'
import { RegisterState } from './context/register/RegisterState'
import { ActivationState } from './context/activation/ActivationState'
import { LessonKnowledgeCheckState } from './context/lessonKnowledgeCheck/lessonKnowledgeCheckState'
import { AuthState } from './context/auth/AuthState'
import { TestPage } from './page/TestPage'
import { GoogleAuthCallback } from './page/GoogleAuthCallback'
import { GoogleAuthState } from './context/auth/google/GoogleAuthState'
import { TermsOfUse } from './page/TermsOfUse'
import { PrivacyPolicy } from './page/PrivacyPolicy'
import { Admin } from './page/admin/Admin'
import { AdminLessonEditor } from './page/admin/AdminLessonEditor'
import { AdminExercise } from './page/admin/AdminExercise'
import { AdminExerciseState } from './context/adminExercise/AdminExerciseState'
import ruRu from 'antd/es/locale/ru_RU'
import { ConfigProvider, Layout, theme } from 'antd'
import { useContext, useEffect } from 'react'
import { UserPrefContext } from './context/userPref/userPrefContext'
import api from './context/auth/AuthState'
import { ThemeModeInterval } from './component/ThemeModeInternal'
import updateTheme from './helper/updateTheme'

const { darkAlgorithm, defaultAlgorithm } = theme;

function App() {
  const {
    setDarkThemeMode,
    theme, setTheme
  } = useContext(UserPrefContext)

  useEffect(() => {
    console.log('useEffect')
    api.get('/user-pref')
        .then(response => {
            setDarkThemeMode(response.data.darkThemeMode)
            updateTheme(response.data.darkThemeMode, setTheme)
        })
    // eslint-disable-next-line
  }, [])

  const isDark = theme === 'DARK'

  return (
    <ConfigProvider
      locale={ruRu}
      theme={{
        token: {
          colorPrimary: isDark ? '#ffadd2' : '#800080',
          colorBgContainer: isDark ? '#36393d' : 'white',
          controlItemBgActive: isDark ? 'gray' : 'silver',
          colorLink: isDark ? '#ffadd2' : '#800080'
        },
        components: {
          Layout: {
            bodyBg: isDark ? '#36393d' : 'white'
          },
          Menu: {
            subMenuItemBg: isDark ? '#36393d' : 'white',
            itemSelectedBg: isDark ? '#fdfdfd1f' : '#eee'
          },
          Logo: {
            firstColor: isDark ? '#ffadd2' : '#800080',
            secondColor: isDark ? '#52c41a' : '#008000'
          },
          Login: {
            colorBgBody: isDark ? '#333' : '#e6f7ff'
          },
          Button: {
            primaryColor: isDark ? 'black' : 'white'
          },
          Editor: {
            themeMode: isDark ? 'dark' : 'light',
            colorBgBody: isDark ? '#36393d' : 'white',
            textColor: isDark ? 'white' : '#24292f',
            tagColor: isDark ? 'lime' : 'purple'
          },
          Text: {
            color: isDark ? 'white' : '#24292f'
          },
          Lesson: {
            passedColor: isDark ? '#008000' : '#52c41a',
            repeatColor: isDark ? '#faad14' : 'orange',
            notPassedColor: isDark ? '#8c8c8c' : '#cccccc',
            tooltipBgBody: isDark ? 'dimgray' : 'dimgray',
            knowledgeCheckProcessBadgeRibbonColor: isDark ? '#595959' : 'gray'
          },
          Icon: {
            bulbColor: isDark ? '#555' : 'silver'
          },
          Level: {
            activeColor: isDark ? 'orange' : 'orange',
            inactiveColor: isDark ? 'gray' : 'silver'
          },
          Exercise: {
            substitutionBgBody: isDark ? '#36393d' : '#fffbe6',
            substitutionBorderColor: isDark ? '#36393d' : '#ffe58f',
            arbitraryValueBadgeColor: isDark ? '#ffadd2' : '#800080'
          },
          Tag: {
            deletedColor: isDark ? '#ad4e00' : 'orange'
          }
        },
        algorithm: isDark ? darkAlgorithm : defaultAlgorithm
      }}
    >
      <Layout style={{minHeight: "100vh", height:"100%", color: isDark ? 'white' : 'black'}}>
        <ThemeModeInterval />
        <BrowserRouter>
          <AuthState>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/lesson" element={<LessonList />} />
              <Route path="/lesson/:id" element={<LessonKnowledgeCheckState><LessonContent /></LessonKnowledgeCheckState>} />
              <Route path="/exercise" element={<Exercise />} />
              <Route path="/dialog" element={<Dialog />} />
              <Route path="/dictionary" element={<Dictionary />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<RegisterState><Register /></RegisterState>} />
              <Route path="/activation" element={<ActivationState><Activation /></ActivationState>} />
              <Route path='/testPage' element={<TestPage />} />
              <Route path='/google/auth/callback' element={<GoogleAuthState><GoogleAuthCallback /></GoogleAuthState>} />
              <Route path='/terms-of-use' element={<TermsOfUse />} />
              <Route path='/privacy-policy' element={<PrivacyPolicy />} />
              <Route path='/admin' element={<Admin />} />
              <Route path='/admin/lessonEditor/:id' element={<AdminLessonEditor />} />
              <Route path='/admin/exercise/:id' element={<AdminExerciseState><AdminExercise /></AdminExerciseState>} />
            </Routes>
          </AuthState>
        </BrowserRouter>
      </Layout>
    </ConfigProvider>
  )
}

export default App
