import api from '../../context/auth/AuthState'
import { Badge, Button, Card, notification, theme } from 'antd'
import { useContext } from 'react'
import { LessonKnowledgeCheckContext } from '../../context/lessonKnowledgeCheck/lessonKnowledgeCheckContext'
import DictionaryService from '../../service/DictionaryService'
import { Exercise } from '../exercise/Exercise'
import { DoubleRightOutlined, FlagOutlined } from '@ant-design/icons'
import showErrorNotification from '../../helper/showErrorNotification'

export const LessonKnowledgeCheckExercise = () => {
    const {token} = theme.useToken()
    const [notificationApi, notificationContextHolder] = notification.useNotification()
    const {
        lessonKnowledgeCheck,
        lessonKnowledgeCheckExercise,
        setLessonKnowledgeCheck,
        setLessonKnowledgeCheckCompleted,
        setLessonKnowledgeCheckExercise,
        lessonKnowledgeCheckShowNextButton
    } = useContext(LessonKnowledgeCheckContext)
    const totalCount = lessonKnowledgeCheck.exerciseCount
    const title = DictionaryService.getExerciseTypeValue(
        lessonKnowledgeCheckExercise.exercise.exerciseType,
        lessonKnowledgeCheckExercise.exercise.dialogType)
    const progress = `${totalCount - lessonKnowledgeCheck.checkExercises.length + 1} / ${totalCount}`
    const goToNextExercise = () => {
        console.log('nextExercise')
        lessonKnowledgeCheck.checkExercises = lessonKnowledgeCheck.checkExercises.slice(1)
        setLessonKnowledgeCheck(lessonKnowledgeCheck)
        setLessonKnowledgeCheckExercise(lessonKnowledgeCheck.checkExercises[0])
    }
    const finish = () => {
        api.put(`/lesson-knowledge-check/${lessonKnowledgeCheck.id}/complete`)
        .then(response => {
            if(response.data.passed) {
                setLessonKnowledgeCheck(null)
                setLessonKnowledgeCheckCompleted(true)
            }
        })
        .catch(e => {
            showErrorNotification(notificationApi, e)
        })
    }
    return (
        <div style={{ padding: '10px' }}>
            { notificationContextHolder }
            <Badge.Ribbon text={progress} color={token.Lesson.knowledgeCheckProcessBadgeRibbonColor}>
                <Card title={title} size="small">
                    <Exercise />
                    <center>
                        {
                            lessonKnowledgeCheckShowNextButton
                                && lessonKnowledgeCheck.checkExercises.length > 1
                            ? (
                                <Button
                                    icon={<DoubleRightOutlined />}
                                    onClick={goToNextExercise}
                                >
                                    Перейти к следующему упражнению
                                </Button>
                            ) : null
                        }
                        {
                            lessonKnowledgeCheckShowNextButton
                            && lessonKnowledgeCheck.checkExercises.length <= 1
                        ? (
                            <Button
                                icon={<FlagOutlined />}
                                onClick={finish}
                            >
                                Завершить
                            </Button>
                        ) : null
                        }
                    </center>
                </Card>
            </Badge.Ribbon>
        </div>
    )

}