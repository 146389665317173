import { AudioFilled } from '@ant-design/icons'
import { Button, Flex, Progress } from 'antd'
import { useEffect, useState } from 'react'
import MicRecorder from 'mic-recorder-to-mp3'
import api from '../context/auth/AuthState'

export const NewAudioRecorder = ({duration}) => {

    const [counter, setCounter] = useState(duration)
    const [recording, setRecording] = useState(false)
    const Mp3Recorder = new MicRecorder({ bitRate: 128 })
    const tick = 200

    useEffect(() => {
        console.log('init NewAudioRecorder')
        checkPermissionForAudio()
    }, [])

    const checkPermissionForAudio = () => {
        navigator.getUserMedia = navigator.getUserMedia || navigator.mediaDevices.getUserMedia
        navigator.getUserMedia(
            { audio: true },
            () => {
                console.log('Permission Granted')
            },
            () => {
                console.log('Permission Denied')
            }
        )
    }

    const startRecord = () => {
        console.log("startRecord")
        Mp3Recorder.start()
            .then(() => {
                setRecording(true)
            })
        for(let time = tick; time < duration; time += tick) {
            setTimeout(() => {
                setCounter(duration - time)
            }, time)
        }
        setTimeout(() => {
            setCounter(0)
            setRecording(false)
            stopRecord()
        }, duration)
    }

    const stopRecord = () => {
        console.log('stopRecord')
        Mp3Recorder.stop().getMp3()
            .then(async ([_, blob]) => {
                sendAudio(blob)
            })
            .catch(e => console.error(e))
    }

    const sendAudio = bytes => {
        let formData = new FormData()
        formData.append("audio", bytes);
        api.post('/speech/uploadAudio', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(resp => {
            console.log('resp', resp.data)
        })
    }

    const renderStartRecordButton = () => {
        if(recording) {
            return null
        }
        return (
            <Button
                icon={<AudioFilled />}
                type="primary"
                shape="circle"
                onClick={startRecord}
                ghost
            />
        )
    }

    const renderRecording = () => {
        if(!recording) {
            return null
        }
        return (
            <Progress
                type="circle"
                percent={100 * counter / duration}
                format={() => ""}
                size={30}
            />
        )
    }

    return (
        <Flex
            align="center"
            justify="center"
            style={{
                width: 35,
                height: 35
            }}
        >
            { renderStartRecordButton() }
            { renderRecording() }
        </Flex>
    )
}