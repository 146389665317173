import { Alert, Button, Collapse, Form, notification} from 'antd'
import api from '../../context/auth/AuthState'
import { useContext, useEffect, useState } from 'react'
import { LessonKnowledgeCheckContext } from '../../context/lessonKnowledgeCheck/lessonKnowledgeCheckContext'
import substitutionRender from '../../helper/substitutionRender'
import showErrorNotification from '../../helper/showErrorNotification'
import { CheckOutlined, LeftCircleOutlined } from '@ant-design/icons'
import substitutionAttempt from '../../helper/substitutionAttempt'
import { SUBSTITUTION_ANSWER_FIELD_PREFIX } from '../../constant/Constants'

export const ExerciseWord = () => {
    const {
        lessonKnowledgeCheckExercise,
        setLessonKnowledgeCheckShowNextButton
    } = useContext(LessonKnowledgeCheckContext)
    const [form] = Form.useForm()
    const [formDisabled, setFormDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(null)
    const [notificationApi, notificationContextHolder] = notification.useNotification()

    const reset = () => {
        form.resetFields()
        setFormDisabled(false)
        setStatus(null)
        setLoading(false)
        setLessonKnowledgeCheckShowNextButton(false)
    }

    useEffect(() => {
        reset()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lessonKnowledgeCheckExercise])

    const check = values => {
        setLoading(true)
        setFormDisabled(true)
        setStatus(null)
        const requestData = {
            'attempt': substitutionAttempt(SUBSTITUTION_ANSWER_FIELD_PREFIX, values)
        }
        api.put(`/lesson-knowledge-check-exercise/${lessonKnowledgeCheckExercise.id}/word`, requestData)
        .then(response => {
            if(response.data.result === 'ACCEPTED') {
                setStatus('ACCEPTED')
                setLessonKnowledgeCheckShowNextButton(true)
            } else if(response.data.result === 'NOT_ACCEPTED') {
                setStatus('NOT_ACCEPTED')
                setFormDisabled(false)
            } else {
                showErrorNotification(notificationApi)
                setFormDisabled(false)
            }
            setLoading(false)
        })
        .catch(e => {
            showErrorNotification(notificationApi, e)
            setLoading(false)
            setFormDisabled(false)
        })
    }

    const items = [{
        key: 'word',
        label: substitutionRender(
            SUBSTITUTION_ANSWER_FIELD_PREFIX,
            lessonKnowledgeCheckExercise.exercise.maskedAnswerKk,
            lessonKnowledgeCheckExercise.exercise?.exerciseDescription?.maxSymbolCount),
            children: lessonKnowledgeCheckExercise.exercise.word.ru,
            collapsible: 'icon'
    }]

    return (
        <>
            {notificationContextHolder}
            <Form
                id="exercise-word-form"
                name="control-ref"
                form={form}
                onFinish={check}
                disabled={formDisabled}
            >
                <Collapse
                    items={items}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => <LeftCircleOutlined rotate={isActive ? -90 : 0} />}
                />
                <br />
                {
                    status === 'ACCEPTED'
                    ? (
                        <Form.Item>
                            <Alert message="Ответ принят!" type="success" showIcon />
                        </Form.Item>
                    ) : null
                }
                {
                    status === 'NOT_ACCEPTED'
                    ? (
                        <Form.Item>
                            <Alert message="Ответ не принят. Попробуйте другой вариант." type="warning" showIcon />
                        </Form.Item>
                    ) : null
                }
                {
                    status !== 'ACCEPTED'
                    ? (
                        <Form.Item>
                            <center>
                                <Button
                                    icon={<CheckOutlined />}
                                    htmlType="submit"
                                    disabled={formDisabled}
                                    loading={loading}
                                >
                                    Проверить
                                </Button>
                            </center>
                        </Form.Item>
                    ) : null
                }
            </Form>
        </>
    )
}