const handlers = {
    SET_CHECKING: state => ({...state, checking: true}),
    AUTH_SUCCESS: state => ({...state, checking: false, result: 'SUCCESS'}),
    AUTH_ERROR: (state, action) => ({...state, checking: false, result: 'ERROR', error: action.error}),
    DEFAULT: state => state
}

export const googleAuthReducer = (state, action) => {
    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}