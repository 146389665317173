import { Alert, Button, notification } from 'antd'
import { useContext } from 'react'
import api from '../../context/auth/AuthState'
import { LessonKnowledgeCheckContext } from '../../context/lessonKnowledgeCheck/lessonKnowledgeCheckContext'
import showErrorNotification from '../../helper/showErrorNotification'
import { LessonKnowledgeCheckExercise } from './LessonKnowledgeCheckExercise'

export const LessonKnowledgeCheck = ({lesson}) => {

    const [notificationApi, notificationContextHolder] = notification.useNotification()

    const {lessonKnowledgeCheck, setLessonKnowledgeCheck,
        lessonKnowledgeCheckExercise, setLessonKnowledgeCheckExercise,
        lessonKnowledgeCheckCompleted, setLessonKnowledgeCheckCompleted
    } = useContext(LessonKnowledgeCheckContext)

    const startLessonKnowledgeCheck = () => {
        setLessonKnowledgeCheckCompleted(false)
        const requestData = {
            lesson: {
                id: lesson.id
            }
        }
        api.post('/lesson-knowledge-check', requestData).then(response => {
            const lessonKnowledgeCheck = response.data
            if(lessonKnowledgeCheck) {
                setLessonKnowledgeCheck(lessonKnowledgeCheck)
                setLessonKnowledgeCheckExercise(lessonKnowledgeCheck.checkExercises[0])
            }
        }).catch(e => {
            showErrorNotification(notificationApi, e)
        })
    }

    if(lessonKnowledgeCheck) {
        if(!lessonKnowledgeCheckExercise) {
            return <>Нет упражнений</>
        }

        return (
            <LessonKnowledgeCheckExercise />
        )
    }

    const completedMessageFragment = lessonKnowledgeCheckCompleted
        ? (
            <Alert message="Проверка завершена!" type="success" showIcon />
        )
        : null

    return (
        <div align="center">
            { notificationContextHolder }
            { completedMessageFragment }
            <br />
            <Button onClick={startLessonKnowledgeCheck}>
                Проверить знания по уроку
            </Button>
        </div>
    )
}