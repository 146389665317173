class ShuffleHelper {

    static stableShuffle = (originalArray, seed) => {
        const arr = [...originalArray]
        let x = seed;
        for(let i = 0; i < arr.length; ++i) {
            x = (10160537 * x + 10158013) % 100000007
            let rndIndex = Math.abs(x) % arr.length
            if(i !== rndIndex) {
                let swap = arr[i]
                arr[i] = arr[rndIndex]
                arr[rndIndex] = swap
            }
        }
        return arr
    }

}

export default ShuffleHelper