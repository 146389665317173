import { Collapse, notification, Select } from 'antd'
import { useEffect, useState } from 'react'
import HighlightHelper from '../helper/HighlightHelper'
import api from '../context/auth/AuthState'
import showErrorNotification from '../helper/showErrorNotification'

export const AdminExerciseDialogSelect = ({value, onChange, onDialogSelect}) => {
    const [dialogOptions, setDialogOptions] = useState([])
    const [dialogMap, setDialogMap] = useState({})
    const [dialog, setDialog] = useState(null)
    const dialogSeparator = '\t/\t'
    const languageSeparator = '\t-\t'
    const [notificationApi, notificationContextHolder] = notification.useNotification()

    useEffect(() => {
        if(!value) {
            return
        }
        api.get(`/admin/dialog/${value}`)
        .then(response => {
            updateDialogOptions([response.data])
        })
        .catch(e => {
            showErrorNotification(notificationApi, e)
        })

    }, [value, notificationApi])

    const searchDialog = query => {
        api.get('/admin/dialog/search', {
            params: {
                query: query
            }
        })
        .then(response => {
            updateDialogOptions(response.data)
        })
        .catch(e => {
            showErrorNotification(notificationApi, e)
        })
    }

    const updateDialogOptions = items => {
        setDialogOptions(items.map(dialog => ({
            value: dialog.id,
            label:  (dialog.questionKk ?
                (dialog.questionKk + languageSeparator + dialog.questionRu + dialogSeparator) : '')  +
                dialog.answerKk + languageSeparator + dialog.answerRu
        })))
        const map = {}
        items.forEach(dialog => {
            map[dialog.id] = dialog
        })
        setDialogMap(map)
    }

    const selectDialog = value => {
        onChange(value)
        api.get(`/admin/dialog/${value}`)
        .then(response => {
            setDialog(response.data)
            onDialogSelect(response.data)
        })
        .catch(e => {
            showErrorNotification(notificationApi, e)
        })
    }

    const renderDialogFragment = () => {
        if(!dialog) {
            return null
        }
        const items = []
        const activeKeys = []
        if(dialog.dialogType === 'DIALOG') {
            items.push({
                key: 'question',
                label: dialog.questionKk,
                children: dialog.questionRu,
                showArrow: false
            })
            activeKeys.push('question')
        }
        items.push({
            key: 'answer',
            label: dialog.answerKk,
            children: dialog.answerRu,
            showArrow: false
        })
        activeKeys.push('answer')
        return (
            <Collapse
                items={items}
                activeKey={activeKeys}
            />
        )
    }

    return (
        <>
            {notificationContextHolder}
            <Select
                options={dialogOptions}
                defaultValue={value}
                placeholder="Найдите и выберите диалог/фразу"
                onSearch={searchDialog}
                onSelect={selectDialog}
                filterOption={false}
                optionRender={option => {
                    const dialog = dialogMap[option.value]
                    if(!dialog) {
                        return "Элемент не найден"
                    }
                    const fragments = []
                    fragments.push(HighlightHelper.highlight(dialog.questionKk, dialog.highlightQuestionKk))
                    fragments.push(HighlightHelper.highlight(dialog.questionRu, dialog.highlightQuestionRu))
                    fragments.push(HighlightHelper.highlight(dialog.answerKk, dialog.highlightAnswerKk))
                    fragments.push(HighlightHelper.highlight(dialog.answerRu, dialog.highlightAnswerRu))
                    fragments.push(<br />)
                    return fragments
                }}
                showSearch
            />
            <br /><br />
            { renderDialogFragment() }
        </>
    )
}