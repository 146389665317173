import { Badge, Button, Form, Input, InputNumber, Select, Space, theme } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

export const AdminExerciseTestTaskArbitraryValueFormFragment = ({form}) => {

    const {token} = theme.useToken()

    const valueTypeOptions = [{
        value: 'INTEGER',
        label: 'Число'
    }, {
        value: 'STRING',
        label: 'Текст'
    }]

    return (
        <>
            <Form.Item
                name={['testTask', 'id']}
                hidden
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={['testTask', 'content']}
                label="Вопрос"
                hasFeedback
                rules={[
                    { required: true, message: 'Укажите вопрос' }
                ]}
            >
                <Input.TextArea
                    rows={5}
                />
            </Form.Item>
            <Form.List
                name={['testTask', 'values']}
            >
                {
                    (fields, {add, remove}, {errors}) => (
                        <>
                            <Form.Item
                                label="Значения"
                            />
                            {
                                fields.map((field, index) => (
                                    <Form.Item
                                        key={field.key}
                                        label={(
                                            <Badge
                                                count={String.fromCharCode(('A'.charCodeAt(0) + index))}
                                                color={token.Exercise.arbitraryValueBadgeColor}
                                            />
                                        )}
                                    >
                                        <Space
                                            align="baseline"
                                        >
                                            <Form.Item
                                                name={[field.name, 'valueType']}
                                                label="Тип значения"
                                                rules={[
                                                    { required: true, message: "Укажите тип значения" }
                                                ]}
                                            >
                                                <Select
                                                    options={valueTypeOptions}
                                                    placeholder="Тип значения"
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                shouldUpdate
                                            >
                                            {
                                                () => {
                                                    return form.getFieldValue(['testTask', 'values', field.name, 'valueType']) === 'INTEGER' ? (
                                                        <Form.Item
                                                            name={[field.name, 'integerValue']}
                                                            label="Числовое значение"
                                                            rules={[
                                                                { required: true, message: "Укажите числовое значение" }
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                placeholder="Число"
                                                            />
                                                        </Form.Item>
                                                    ) : null
                                                }
                                            }
                                            </Form.Item>
                                            <Form.Item
                                                shouldUpdate
                                            >
                                            {
                                                () => {
                                                    return form.getFieldValue(['testTask', 'values', field.name, 'valueType']) === 'STRING' ? (
                                                        <Form.Item
                                                            name={[field.name, 'stringValue']}
                                                            label="Текстовое значение"
                                                            rules={[
                                                                { required: true, message: "Укажите текстовое значение" }
                                                            ]}
                                                        >
                                                            <Input
                                                                placeholder="Текст"
                                                            />
                                                        </Form.Item>
                                                    ) : null
                                                }
                                            }
                                            </Form.Item>
                                            {
                                                fields.length > 1 ? (
                                                    <MinusCircleOutlined
                                                        onClick={() => remove(field.name)}
                                                    />
                                                ) : null
                                            }
                                        </Space>
                                    </Form.Item>
                                ))
                            }
                            <Form.Item>
                                <center>
                                    <Button
                                        type="dashed"
                                        icon={<PlusOutlined />}
                                        onClick={() => add()}
                                    >
                                        Добавить вариант значения
                                    </Button>
                                </center>
                            </Form.Item>
                        </>
                    )
                }
            </Form.List>
        </>
    )
}