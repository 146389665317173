const handlers = {
    SET_DARK_THEME_MODE: (state, action) => ({...state, darkThemeMode: action.darkThemeMode}),
    SET_THEME: (state, action) => ({...state, theme: action.theme}),
    SET_GEOLOCATION_ERROR: (state, action) => ({...state, geolocationError: action.geolocationError}),
    DEFAULT: state => state
}

export const userPrefReducer = (state, action) => {
    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}