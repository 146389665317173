import { Button, Input, Popover, theme } from 'antd'
import { useRef, useState } from 'react'

export const InputField = ({value, onChange, maxSymbolCount}) => {

    const [inputValue, setInputValue] = useState(value)
    const {token} = theme.useToken()
    const inputRef = useRef(null)
    const letterWidth = 15

    const letters = ['ә', 'і', 'ң', 'ғ', 'ү', 'ұ', 'қ', 'ө', 'һ']

    const handleInputChange = e => {
        setInputValue(e.target.value)
        onChange(e.target.value)
    }

    const handleLetterClick = letter => {
        if(!inputValue) {
            updateValue(letter)
        } else {
            if(inputValue.length < maxSymbolCount) {
                updateValue(inputValue + letter)
            }
        }
    }

    const handleBackspaceClick = () => {
        if(inputValue) {
            updateValue(inputValue.substring(0, inputValue.length - 1))
        }
    }

    const handleClearClick = () => {
        updateValue('')
    }

    const updateValue = value => {
        setInputValue(value)
        onChange(value)
    }

    const lettersButtonFragment = letters.map(letter => (
        <Button
            onClick={() => handleLetterClick(letter)}
            style={{margin: 3, padding: 5}}
        >
            { letter }
        </Button>
    ))

    const keypad = (
        <div style={{textAlign: 'center'}}>
            {lettersButtonFragment}
            <Button onClick={handleBackspaceClick}>
                ←
            </Button>
            <br /><br />
            <Button onClick={handleClearClick}>
                Очистить
            </Button>
        </div>
    )

    return (
        <Popover
            content={keypad}
            trigger="hover"
            placement="bottom"
        >
            <Input
                ref={inputRef}
                defaultValue={value}
                value={inputValue}
                onChange={handleInputChange}
                variant="filled"
                maxLength={maxSymbolCount}
                style={{
                    width: maxSymbolCount * letterWidth,
                    padding: 0,
                    color: token.Exercise.substitutionTextColor,
                    backgroundColor: token.Exercise.substitutionBgBody,
                    border: 'solid 1px ' + token.Exercise.substitutionBorderColor
                }}
            />
        </Popover>
    )

}