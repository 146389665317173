import { StarTwoTone } from '@ant-design/icons'
import { theme } from 'antd'

export const Level = ({value, count}) => {
    const {token} = theme.useToken()
    return (
        Array.from({length: count}, (_, index) => {
            if(index < value) {
                return (
                    <StarTwoTone key={index} twoToneColor={token.Level.activeColor} style={{padding: 5}} />
                )
            } else {
                return (
                    <StarTwoTone key={index} twoToneColor={token.Level.inactiveColor} style={{padding: 5}} />
                )
            }
        })
    )
}