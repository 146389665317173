import MDEditor from '@uiw/react-md-editor'
import { Button, theme } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

export const AdminExerciseLessonTab = ({lesson}) => {
    const {token} = theme.useToken()
    return (
        <>
            <div data-color-mode={token.Editor.themeMode}>
                <MDEditor.Markdown
                    source={lesson.content}
                    style={{
                        background: token.Editor.colorBgBody,
                        color: token.Editor.textColor
                    }}
                />
            </div>
            <div align="right">
                <Button type="link">
                    <EditOutlined />
                    &nbsp;
                    <Link to={'/admin/lessonEditor/' + lesson.id}>
                        Перейти в редактор
                    </Link>
                </Button>
            </div>
        </>
    )
}