import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import { UserPrefState } from './context/userPref/userPrefState'

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

root.render(
  // <React.StrictMode>
  <UserPrefState>
    <App />
  </UserPrefState>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
