import { useContext } from 'react'
import { LessonKnowledgeCheckContext } from '../../context/lessonKnowledgeCheck/lessonKnowledgeCheckContext'
import { ExerciseTestTaskArbitraryValue } from './ExerciseTestTaskArbitraryValue'
import { ExerciseTestTaskMultipleChoice } from './ExerciseTestTaskMultipleChoice'
import { ExerciseTestTaskSingleChoice } from './ExerciseTestTaskSingleChoice'

export const ExerciseTestTask = () => {
    const {lessonKnowledgeCheckExercise} = useContext(LessonKnowledgeCheckContext)
    console.log('ExerciseTestTask...', lessonKnowledgeCheckExercise)
    switch(lessonKnowledgeCheckExercise.exercise.testTask.taskType) {
        case 'ARBITRARY_VALUE':
            return (
                <ExerciseTestTaskArbitraryValue />
            )
        case 'MULTIPLE_CHOICE':
            return (
                <ExerciseTestTaskMultipleChoice />
            )
        case 'SINGLE_CHOICE':
            return (
                <ExerciseTestTaskSingleChoice />
            )
        default:
            return (
                <div>Unknown task type</div>
            )
    }
}