import api from '../../context/auth/AuthState'
import { useNavigate } from 'react-router-dom'
import { Container } from '../../component/Container'
import { ProfileDesign } from './ProfileDesign'
import { Button, Tabs } from 'antd'

export const Profile = () => {

    const navigate = useNavigate()

    const logout = () => {
        api.delete('/logout')
            .catch(result => {
                if(result) {
                    navigate('/login')
                }
            })
    }

    const items = [{
        key: 'profileLearning',
        label: 'Обучение',
        children: <b>Profile Learning</b>
    }, {
        key: 'profileDesign',
        label: 'Оформление',
        children: <ProfileDesign />
    }, {
        key: 'profileReminder',
        label: 'Напоминания',
        children: <b>Profile Reminder</b>
    }, {
        key: 'profileLogout',
        label: 'Выход',
        children: (
            <Button type='primary' onClick={logout} ghost>
                Выйти
            </Button>
        )
    }]

    return (
        <Container>
            <Tabs
                tabPosition='left'
                items={items}
            />
        </Container>
    )
}