const handlers = {
    SET_SENDING: state => ({...state, sending: true}),
    REGISTER_SUCCESS: state => ({...state, sending: false, flagSuccess: true, flagError: false, flagForm: false}),
    REGISTER_ERROR: (state, action) => ({...state, sending: false, flagSuccess: false, flagError: true, errorMessage: action.errorMessage, errorType: action.errorType}),
    DEFAULT: state => state
}

export const registerReducer = (state, action) => {
    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}