class HighlightHelper {
    static boldHighlight = (text, value) => {
        var parts = text.split(value)
        var buffer = []
        parts.forEach(part => {
            buffer.push(part)
            buffer.push(<span style={{backgroundColor: 'yellow'}}>{value}</span>)
        })
        buffer.pop()
        return <>{buffer}</>
    }

    static highlight = (text, highlights) => {
        if(!text) {
            return null
        }
        if(!highlights) {
            highlights = []
        }
        const buffer = []
        let index = 0
        highlights.forEach(highlight => {
            buffer.push(text.substring(index, highlight.start))
            buffer.push(<span style={{backgroundColor: 'yellow'}}>{text.substring(highlight.start, highlight.end)}</span>)
            index = highlight.end
        })
        buffer.push(text.substring(index))
        return <div>{buffer}</div>
    }
}

export default HighlightHelper