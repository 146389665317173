import api from '../../context/auth/AuthState'
import { Alert, Button, Form, notification } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { OrderableSentence } from '../../component/OrderableSentence'
import { LessonKnowledgeCheckContext } from '../../context/lessonKnowledgeCheck/lessonKnowledgeCheckContext'
import { CheckOutlined } from '@ant-design/icons'
import orderAttempt from '../../helper/orderAttempt'
import showErrorNotification from '../../helper/showErrorNotification'

export const ExerciseOrder = () => {
    const {
        lessonKnowledgeCheckExercise,
        setLessonKnowledgeCheckShowNextButton
    } = useContext(LessonKnowledgeCheckContext)
    const [form] = Form.useForm()
    const [formDisabled, setFormDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(null)
    const [notificationApi, notificationContextHolder] = notification.useNotification()

    const reset = () => {
        form.resetFields()
        setFormDisabled(false)
        setStatus(null)
        setLoading(false)
        setLessonKnowledgeCheckShowNextButton(false)
    }

    useEffect(() => {
        reset()
        if(lessonKnowledgeCheckExercise.exercise.dialog.dialogType === 'DIALOG') {
            form.setFieldValue('question', lessonKnowledgeCheckExercise.exercise.maskedQuestionKk.split('|'))
        }
        form.setFieldValue('answer', lessonKnowledgeCheckExercise.exercise.maskedAnswerKk.split('|'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lessonKnowledgeCheckExercise])

    const check = values => {
        console.log('CHECK', values)
        setLoading(true)
        setFormDisabled(true)
        setStatus(null)
        const requestData = {
            'questionAttempt': orderAttempt(values.question),
            'answerAttempt': orderAttempt(values.answer),
        }
        api.put(`/lesson-knowledge-check-exercise/${lessonKnowledgeCheckExercise.id}/order`, requestData)
        .then(response => {
            if(response.data.result === 'ACCEPTED') {
                setStatus('ACCEPTED')
                setLessonKnowledgeCheckShowNextButton(true)
            } else if(response.data.result === 'NOT_ACCEPTED') {
                setStatus('NOT_ACCEPTED')
                setFormDisabled(false)
            } else {
                showErrorNotification(notificationApi)
                setFormDisabled(false)
            }
            setLoading(false)
        })
        .catch(e => {
            showErrorNotification(notificationApi, e)
            setLoading(false)
            setFormDisabled(false)
        })
    }

    return (
        <>
            {notificationContextHolder}
            <Form
                id="exercise-order-form"
                name="control-ref"
                form={form}
                onFinish={check}
                disabled={formDisabled}
            >
                <Form.Item name="question" noStyle>
                    <OrderableSentence />
                </Form.Item>
                <Form.Item name="answer" noStyle>
                    <OrderableSentence />
                </Form.Item>
                {
                    status === 'ACCEPTED'
                    ? (
                        <Form.Item>
                            <Alert message="Ответ принят!" type="success" showIcon />
                        </Form.Item>
                    ) : null
                }
                {
                    status === 'NOT_ACCEPTED'
                    ? (
                        <Form.Item>
                            <Alert message="Ответ не принят. Попробуйте другой вариант." type="warning" showIcon />
                        </Form.Item>
                    ) : null
                }
                {
                    status !== 'ACCEPTED'
                    ? (
                        <Form.Item>
                            <center>
                                <Button
                                    icon={<CheckOutlined />}
                                    htmlType="submit"
                                    disabled={formDisabled}
                                    loading={loading}
                                >
                                    Проверить
                                </Button>
                            </center>
                        </Form.Item>
                    ) : null
                }
            </Form>
        </>
    )
}