import { useReducer } from 'react'
import { SET_ADMIN_EXERCISE, SET_ADMIN_EXERCISE_MODAL_OPEN, SET_ADMIN_EXERCISE_LESSON, SET_ADMIN_LESSON_EXERCISES } from '../types'
import { AdminExerciseContext } from './adminExerciseContext'
import { adminExerciseReducer } from './adminExerciseReducer'

export const AdminExerciseState = ({children}) => {
    const initialState = {
        modalOpen: false,
        exercise: null,
        lesson: null,
        lessonExercises: []
    }

    const [state, dispatch] = useReducer(adminExerciseReducer, initialState)

    const setModalOpen = value => dispatch({type: SET_ADMIN_EXERCISE_MODAL_OPEN, modalOpen: value})
    const setExercise = value => dispatch({type: SET_ADMIN_EXERCISE, exercise: value})
    const setLesson = value => dispatch({type: SET_ADMIN_EXERCISE_LESSON, lesson: value})
    const setLessonExercises = value => dispatch({type: SET_ADMIN_LESSON_EXERCISES, lessonExercises: value})

    const {modalOpen, exercise, lesson, lessonExercises} = state

    return (
        <AdminExerciseContext.Provider value={{
            modalOpen, setModalOpen,
            exercise, setExercise,
            lesson, setLesson,
            lessonExercises, setLessonExercises
        }}>
            { children }
        </AdminExerciseContext.Provider>
    )
}