import { Alert, Button, Form, Input, notification } from 'antd'
import { useContext, useEffect, useState } from 'react'
import api from '../../context/auth/AuthState'
import { LessonKnowledgeCheckContext } from '../../context/lessonKnowledgeCheck/lessonKnowledgeCheckContext'
import showErrorNotification from '../../helper/showErrorNotification'
import { CheckOutlined } from '@ant-design/icons'

export const ExerciseTestTaskArbitraryValue = () => {

    const {
        lessonKnowledgeCheckExercise,
        setLessonKnowledgeCheckShowNextButton
    } = useContext(LessonKnowledgeCheckContext)
    const [form] = Form.useForm()
    const [formDisabled, setFormDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(null)
    const [notificationApi, notificationContextHolder] = notification.useNotification()

    const reset = () => {
        form.resetFields()
        setFormDisabled(false)
        setStatus(null)
        setLoading(false)
        setLessonKnowledgeCheckShowNextButton(false)
    }

    useEffect(() => {
        reset()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lessonKnowledgeCheckExercise])

    const check = requestData => {
        console.log('check', requestData)
        setLoading(true)
        setFormDisabled(true)
        setStatus(null)
        api.put(`/lesson-knowledge-check-exercise/${lessonKnowledgeCheckExercise.id}/test-task/arbitrary-value`, requestData)
        .then(response => {
            if(response.data.result === 'ACCEPTED') {
                setStatus('ACCEPTED')
                setLessonKnowledgeCheckShowNextButton(true)
            } else if(response.data.result === 'NOT_ACCEPTED') {
                setStatus('NOT_ACCEPTED')
                setFormDisabled(false)
            } else {
                showErrorNotification(notification)
                setFormDisabled(false)
            }
            setLoading(false)
        })
        .catch(e => {
            showErrorNotification(notificationApi, e)
            setLoading(false)
            setFormDisabled(false)
        })
    }

    return (
        <>
            {notificationContextHolder}
            {lessonKnowledgeCheckExercise.exercise.testTask.content}
            <br /><br />
            <Form
                id="exercise-test-task-arbitrary-value-form"
                name="control-ref"
                form={form}
                onFinish={check}
                disabled={formDisabled}
            >
                <Form.Item
                    label="Ваш ответ"
                    name="value"
                    rules={[{ required: true, message: 'Введите ответ' }]}
                >
                    <Input placeholder="Введите ответ" />
                </Form.Item>
                {
                    status === 'ACCEPTED'
                    ? (
                        <Form.Item>
                            <Alert message="Ответ принят!" type="success" showIcon />
                        </Form.Item>
                    ) : null
                }
                {
                    status === 'NOT_ACCEPTED'
                    ? (
                        <Form.Item>
                            <Alert message="Ответ не принят. Попробуйте другой вариант." type="warning" showIcon />
                        </Form.Item>
                    ) : null
                }
                {
                    status !== 'ACCEPTED'
                    ? (
                        <Form.Item>
                            <center>
                                <Button
                                    icon={<CheckOutlined />}
                                    htmlType="submit"
                                    disabled={formDisabled}
                                    loading={loading}
                                >
                                    Проверить
                                </Button>
                            </center>
                        </Form.Item>
                    ) : null
                }
            </Form>
        </>
    )

}