import { Component } from 'react'
import MicRecorder from 'mic-recorder-to-mp3'
import { AudioOutlined, BorderOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import api from '../context/auth/AuthState'

const Mp3Recorder = new MicRecorder({ bitRate: 128 })

class AudioRecorder extends Component {

    constructor(props) {
        super(props)
        this.state = {
            blocked: false,
            recording: false
        }
    }

    componentDidMount() {
        console.log('init AudioRecorder')
        this.checkPermissionForAudio()
    }

    checkPermissionForAudio = () => {
        navigator.getUserMedia = navigator.getUserMedia || navigator.mediaDevices.getUserMedia
        navigator.getUserMedia(
            { audio: true },
            () => {
                console.log('Permission Granted')
            },
            () => {
                console.log('Permission Denied')
            }
        )
    }

    startRecord = () => {
        if(this.state.blocked) {
            console.log('Permission Denied')
        } else {
            console.log('start...')
            Mp3Recorder.start()
                .then(() => {
                    this.setState({recording: true})
                })
                .catch(e => console.error(e))
        }
    }

    stopRecord = () => {
        console.log('stopRecord')
        Mp3Recorder.stop().getMp3()
            .then(async ([buffer, blob]) => {
                this.sendAudio(blob)
                this.setState({recording: false})
            })
            .catch(e => console.error(e))
    }

    sendAudio = (bytes) => {
        let formData = new FormData()
        formData.append("audio", bytes);
        const response = api.post('/speech/uploadAudio', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        response.then(resp => {
            console.log('resp', resp.data)
        })
    }

    render() {
        return (
            <div style={{background: 'purple', padding: 10}}>
                <Button type='primary' onClick={this.startRecord} disabled={this.blocked} ghost>
                    <AudioOutlined />
                    Start Record
                </Button>
                &nbsp;
                <Button type='primary' onClick={this.stopRecord} disabled={this.blocked} ghost>
                    <BorderOutlined />
                    Stop Record
                </Button>
                {
                    this.recording ? "Идет запись" : null
                }
            </div>    
        )
    }

}

export default AudioRecorder