import { Checkbox, Divider, Form, Input, notification, Select } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { AdminExerciseDescriptionSelect } from '../../component/AdminExerciseDescriptionSelect'
import { AdminExerciseContext } from '../../context/adminExercise/adminExerciseContext'
import api from '../../context/auth/AuthState'
import showErrorNotification from '../../helper/showErrorNotification'
import showSavedNotification from '../../helper/showSavedNotification'

export const AdminExerciseWordForm = ({updateLessonExercises}) => {
    const {exercise, setModalOpen, lesson} = useContext(AdminExerciseContext)
    const [form] = Form.useForm()
    const [wordOptions, setWordOptions] = useState([])
    const [wordMap, setWordMap] = useState({})
    const [notificationApi, notificationContextHolder] = notification.useNotification()

    useEffect(() => {
        console.log('AdminExerciseWordForm')
        if(exercise?.id) {
            api.get(`/admin/exercise/word/${exercise.id}`)
            .then(response => {
                form.resetFields()
                form.setFieldsValue(response.data)
            })
            .catch(e => {
                showErrorNotification(notificationApi, e)
            })
        } else {
            form.resetFields()
            form.setFieldsValue({
                exerciseType: 'WORD',
                lesson: lesson,
                deleted: false
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exercise])

    const saveWord = values => {
        console.log('saveWord', values)
        api.put('/admin/exercise/word', values).then(() => {
            showSavedNotification(notification)
            setModalOpen(false)
            updateLessonExercises(lesson.id)
        }).catch(e => {
            showErrorNotification(notificationApi, e)
        })
    }

    const searchWord = query => {
        api.get('/admin/word/search', {
            params: {
                query: query
            }
        })
            .then(response => {
                setWordOptions(response.data.map(word => ({
                    value: word.id,
                    label: word.kk + ' - ' + word.ru
                })))
                setWordMap(
                    Object.fromEntries(
                        response.data.map(word => [word.id, word])))
            })
            .catch(e => {
                showErrorNotification()
            })
    }

    const selectWord = id => {
        form.setFieldsValue({answerKk: wordMap[id].kk})
    }

    return (
        <>
            {notificationContextHolder}
            <Divider>
                Подстановка в слово
            </Divider>
            <Form
                id="admin-exercise-form"
                name="control-ref"
                form={form}
                onFinish={saveWord}
            >
                <Form.Item
                    name="id"
                    hidden
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={['lesson', 'id']}
                    hidden
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="exerciseType"
                    hidden
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={['word', 'id']}
                    label="Слово"
                    rules={[
                        { required: true, message: 'Выберите слово' }
                    ]}
                >
                    {
                        <Select
                            options={wordOptions}
                            placeholder="Найдите и выберите слово"
                            onSearch={searchWord}
                            onSelect={selectWord}
                            filterOption={false}
                            showSearch
                        />
                    }
                </Form.Item>
                <Form.Item
                    name="answerKk"
                    label="Задание"
                    rules={[
                        { required: true, message: 'Укажите задание' }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={['exerciseDescription', 'id']}
                    label="Описание"
                    rules={[
                        { required: true, message: 'Выберите описание' }
                    ]}
                >
                    <AdminExerciseDescriptionSelect />
                </Form.Item>
                <Form.Item
                    name="deleted"
                    valuePropName="checked"
                >
                    <Checkbox>
                        Удалено
                    </Checkbox>
                </Form.Item>
            </Form>
        </>
    )
}