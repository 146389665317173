import { Button, Form, Input, Modal, notification, Space, Switch, Tag, theme } from 'antd'
import { useEffect, useState } from 'react'
import { CustomTable } from '../../component/CustomTable'
import api from '../../context/auth/AuthState'
import showErrorNotification from '../../helper/showErrorNotification'
import showSavedNotification from '../../helper/showSavedNotification'
import { EditOutlined } from '@ant-design/icons'

export const AdminExerciseDescriptionList = () => {

    const [loading, setLoading] = useState(true)
    const [exerciseDescriptions, setExerciseDescriptions] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [form] = Form.useForm()
    const modalWidth = window.innerWidth * 0.8
    const {token} = theme.useToken()
    const [notificationApi, notificationContextHolder] = notification.useNotification()

    const getExerciseDescriptions = () => {
        api.get('/admin/exercise-description').then(response => {
            response.data.map(x => x.key = x.id)
            setExerciseDescriptions(response.data)
        }).catch(e => {
            showErrorNotification(notificationApi, e)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getExerciseDescriptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addButtonClick = () => {
        form.resetFields()
        form.setFieldsValue({deleted: false})
        setModalOpen(true)
    }

    const editButtonClick = lessonGroup => {
        form.setFieldsValue(lessonGroup)
        setModalOpen(true)
    }

    const columns = [{
        key: 'ru',
        title: 'Название на русском',
        dataIndex: 'ru'
    }, {
        key: 'kk',
        title: 'Название на казахском',
        dataIndex: 'kk'
    }, {
        key: 'maxSymbolCount',
        title: 'Максимальное кол-во символов',
        dataIndex: 'maxSymbolCount'
    }, {
        key: 'deleted',
        title: 'Удалено',
        dataIndex: 'deleted',
        render: deleted => deleted ? <Tag color={token.Tag.deletedColor}>Удалено</Tag> : null
    }, {
        key: 'x',
        title: 'Действия',
        dataIndex: '',
        render: row => (
            <Space>
                <Button onClick={() => editButtonClick(row)}><EditOutlined /></Button>
            </Space>
        )
    }]

    const saveExerciseDescription = values => {
        api.put('/admin/exercise-description', values).then(() => {
            showSavedNotification(notificationApi)
            getExerciseDescriptions()
            setModalOpen(false)
        }).catch(e => {
            showErrorNotification(notificationApi, e)
        })
    }

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    }

    return (
        <>
            {notificationContextHolder}
            <Space style={{ marginBottom: 10 }}>
                <Button
                    onClick={addButtonClick}
                >
                    Добавить описание упражнения
                </Button>
            </Space>
            <CustomTable
                columns={columns}
                loading={loading}
                dataSource={exerciseDescriptions}
                pagination={false}
            />
            <Modal
                title="Создать или изменить описание упражения"
                okText="Сохранить"
                cancelText="Отмена"
                open={modalOpen}
                okButtonProps={{form:'lesson-group-form', key: 'submit', htmlType: 'submit', ghost: true}}
                onCancel={() => setModalOpen(false)}
                width={modalWidth}
            >
               <Form
                    id="lesson-group-form"
                    {...formItemLayout}
                    name="control-ref"
                    form={form}
                    onFinish={saveExerciseDescription}
               >
                    <Form.Item
                        label="ID"
                        name="id"
                    >
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        label="Название на русском"
                        name="ru"
                        rules={[{required: true, message: 'Введите наименование на русском'}]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Название на казахском"
                        name="kk"
                        rules={[{required: true, message: 'Введите наименование на казахском'}]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Максимальное кол-во символов"
                        name="maxSymbolCount"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item 
                        label="Отметка об удалении" 
                        name="deleted"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}