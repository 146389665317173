class ErrorHelper {

    static getError = (code, ...maps) => {
        let ret = false
        maps.forEach(map => {
            if(map[code]) {
                ret = map[code]
                return
            }
        })
        return ret
    }

}

export default ErrorHelper