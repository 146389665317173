import { Checkbox, Divider, Form, Input, notification } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useContext, useEffect } from 'react'
import { AdminExerciseDescriptionSelect } from '../../component/AdminExerciseDescriptionSelect'
import { AdminExerciseDialogSelect } from '../../component/AdminExerciseDialogSelect'
import { AdminExerciseContext } from '../../context/adminExercise/adminExerciseContext'
import api from '../../context/auth/AuthState'
import showErrorNotification from '../../helper/showErrorNotification'
import showSavedNotification from '../../helper/showSavedNotification'

export const AdminExerciseOrderForm = ({updateLessonExercises}) => {
    const {exercise, setModalOpen, lesson} = useContext(AdminExerciseContext)
    const [form] = Form.useForm()
    const [notificationApi, notificationContextHolder] = notification.useNotification()

    useEffect(() => {
        console.log('AdminExerciseOrderForm')
        if(exercise?.id) {
            api.get(`/admin/exercise/order/${exercise.id}`)
            .then(response => {
                form.resetFields()
                form.setFieldsValue(response.data)
            })
            .catch(e => {
                showErrorNotification(notificationApi, e)
            })
        } else {
            form.resetFields()
            form.setFieldsValue({
                exerciseType: 'ORDER',
                lesson: lesson,
                deleted: false
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exercise])

    const saveOrder = values => {
        console.log('saveOrder', values)
        api.put('/admin/exercise/order', values).then(() => {
            showSavedNotification(notificationApi)
            setModalOpen(false)
            updateLessonExercises(lesson.id)
        }).catch(e => {
            showErrorNotification(notificationApi, e)
        })
    }

    const onDialogSelect = dialog => {
        console.log('onDialogSelect', dialog)
        form.setFieldValue('dialogType', dialog.dialogType)
        form.setFieldValue('questionKk', dialog.questionKk)
        form.setFieldValue('answerKk', dialog.answerKk)
    }

    return (
        <>
            {notificationContextHolder}
            <Divider>
                Упорядочивание элементов в диалоге
            </Divider>
            <Form
                id="admin-exercise-form"
                name="control-ref"
                form={form}
                onFinish={saveOrder}
                layout="vertical"
            >
                <Form.Item
                    name="id"
                    hidden
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={['lesson', 'id']}
                    hidden
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="exerciseType"
                    hidden
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="dialogType"
                    hidden
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={['dialog', 'id']}
                    label="Диалог / фраза"
                    rules={[
                        { required: true, message: 'Выберите диалог / фразу' }
                    ]}
                >
                    <AdminExerciseDialogSelect
                        onDialogSelect={onDialogSelect}
                    />
                </Form.Item>
                <Form.Item
                    shouldUpdate
                >
                    {
                        () => {
                            if(form.getFieldValue('dialogType') === 'DIALOG') {
                                return (
                                    <>
                                        <Form.Item
                                            name="questionKk"
                                            label="Вопрос задания"
                                            rules={[
                                                { required: true, message: 'Укажите вопрос задания' }
                                            ]}
                                        >
                                            <TextArea />
                                        </Form.Item>
                                        <Form.Item
                                            name="answerKk"
                                            label="Ответ задания"
                                            rules={[
                                                { required: true, message: 'Укажите ответ задания' }
                                            ]}
                                        >
                                            <TextArea />
                                        </Form.Item>
                                    </>)
                            } else if(form.getFieldValue('dialogType') === 'PHRASE') {
                                return (
                                    <Form.Item
                                        name="answerKk"
                                        label="Фраза задания"
                                        rules={[
                                            { required: true, message: 'Укажите фразу задания' }
                                        ]}
                                    >
                                        <TextArea />
                                    </Form.Item>
                                )
                            }
                        }
                    }
                </Form.Item>
                <Form.Item
                    name={['exerciseDescription', 'id']}
                    label="Описание"
                    rules={[
                        { required: true, message: 'Выберите описание' }
                    ]}
                >
                    <AdminExerciseDescriptionSelect />
                </Form.Item>
                <Form.Item
                    name="deleted"
                    valuePropName="checked"
                >
                    <Checkbox>
                        Удалено
                    </Checkbox>
                </Form.Item>
            </Form>
        </>
    )
}