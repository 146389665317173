export const Container = ({children}) => {

    return (
        <div style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10
        }}
        >
            {children}
        </div>
    )

}