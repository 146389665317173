import { Row, Col, Alert } from 'antd'

export const Dictionary = () => {
    return (
        <Row style={{paddingTop: 20}}>
            <Col span={12} offset={6}>
                <Alert message="Справочники в разработке..." type="warning" />
                <Alert message="Запоминание слов в разработке..." type="warning" />
            </Col>
        </Row>
    )
}