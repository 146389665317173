const handlers = {
    SET_LESSON_KNOWLEDGE_CHECK: (state, action) => ({...state, lessonKnowledgeCheck: action.lessonKnowledgeCheck}),
    SET_LESSON_KNOWLEDGE_CHECK_COMPLETED: (state, action) => ({...state, lessonKnowledgeCheckCompleted: action.lessonKnowledgeCheckCompleted}),
    SET_LESSON_KNOWLEDGE_CHECK_EXERCISE: (state, action) => ({...state, lessonKnowledgeCheckExercise: action.lessonKnowledgeCheckExercise}),
    SET_LESSON_KNOWLEDGE_CHECK_SHOW_NEXT_BUTTON: (state, action) => ({...state, lessonKnowledgeCheckShowNextButton: action.lessonKnowledgeCheckShowNextButton}),
    DEFAULT: state => state
}

export const lessonKnowledgeCheckReducer = (state, action) => {
    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}