import { TEST_URL } from '../constant/Constants'

class FeatureFlagService {

    showTestPage() {
        return window.location.href.startsWith(TEST_URL)
    }

    needToFillLoginAndPassword() {
        return window.location.href.startsWith(TEST_URL)
    }

}

export default new FeatureFlagService()