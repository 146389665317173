import { Alert, Col, Row } from 'antd'

export const TryAgain = () => {
    return (
        <Row style={{paddingTop: 20}}>
            <Col span={12} offset={6}>
                <Alert
                    message="Попробуйте еще раз..."
                    type="warning"
                />
            </Col>
        </Row>
        
    )
}