const handlers = {
    SET_ADMIN_EXERCISE_MODAL_OPEN: (state, action) => ({...state, modalOpen: action.modalOpen}),
    SET_ADMIN_EXERCISE: (state, action) => ({...state, exercise: action.exercise}),
    SET_ADMIN_EXERCISE_LESSON: (state, action) => ({...state, lesson: action.lesson}),
    SET_ADMIN_LESSON_EXERCISES: (state, action) => ({...state, lessonExercises: action.lessonExercises}),
    DEFAULT: state => state
}

export const adminExerciseReducer = (state, action) => {
    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}