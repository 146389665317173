import { Button, Checkbox, Form, Input, Space } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

export const AdminExerciseTestTaskMultipleChoiceFormFragment = () => {

    return (
        <>
            <Form.Item
                name={['testTask', 'id']}
                hidden
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={['testTask', 'content']}
                label="Вопрос"
                hasFeedback
                rules={[
                    { required: true, message: 'Укажите вопрос' }
                ]}
            >
                <Input.TextArea
                    rows={5}
                />
            </Form.Item>
            <Form.List
                name={['testTask', 'items']}
            >
                {
                    (fields, {add, remove}, {errors}) => (
                        <>
                            <Form.Item
                                label="Варианты"
                            />
                            {
                                fields.map(field => (
                                    <Form.Item
                                        key={field.key}
                                    >
                                        <Space>
                                            <Form.Item
                                                name={[field.name, 'flagCorrect']}
                                                valuePropName="checked"
                                            >
                                                <Checkbox />
                                            </Form.Item>
                                            <Form.Item
                                                name={[field.name, 'content']}
                                                rules={[
                                                    { required: true, message: "Укажите содержимое" }
                                                ]}
                                            >
                                                <Input.TextArea autoSize={true} cols={1000} />
                                            </Form.Item>
                                            {
                                                fields.length > 1 ? (
                                                    <MinusCircleOutlined
                                                        onClick={() => remove(field.name)}
                                                    />
                                                ) : null
                                            }
                                        </Space>
                                    </Form.Item>
                                ))
                            }
                            <Form.Item>
                                <center>
                                    <Button
                                        type="dashed"
                                        icon={<PlusOutlined />}
                                        onClick={() => add()}
                                    >
                                        Добавить вариант
                                    </Button>
                                </center>
                            </Form.Item>
                        </>
                    )
                }
            </Form.List>
        </>
    )
}