import { useEffect, useState } from 'react'
import { CustomTable } from "../../component/CustomTable"
import { Button, notification, Select, Space, Tag, theme, Tooltip } from 'antd'
import { CarryOutOutlined, EditOutlined } from '@ant-design/icons'
import api from '../../context/auth/AuthState'
import showErrorNotification from '../../helper/showErrorNotification'
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'

export const AdminLessonList = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [lessons, setLessons] = useState([])
    const [lessonGroupOptions, setLessonGroupOptions] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const [lessonGroup, setLessonGroup] = useState(null)
    const {token} = theme.useToken()
    const lessonGroupId = +searchParams.get('lessonGroupId')
    const [notificationApi, notificationContextHolder] = notification.useNotification()

    const getLessons = (lessonGroupId) => {
        const url = lessonGroupId
            ? `/admin/lesson?lessonGroupId=${lessonGroupId}`
            : `/admin/lesson`
        api.get(url).then(response => {
            let number = 0
            response.data.forEach(row => {
                row.rowNumber = ++number
                row.key = row.id
                row.groupNameRu = row.lessonGroup.ru
            })
            setLessons(response.data)
        }).catch(e => {
            showErrorNotification(notificationApi, e)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        const getLessonGroups = () => {
            api.get('/admin/lesson-group').then(response => {
                const options = response.data.map(x => ({
                    value: x.id,
                    label: x.ru
                }))
                const lessonGroup = options.find(option => option.value === lessonGroupId)
                if(lessonGroup) {
                    setLessonGroup(lessonGroup)
                }
                setLessonGroupOptions(options)
            }).catch(e => {
                showErrorNotification(notificationApi, e)
            })
        }
        getLessonGroups()
        getLessons(lessonGroupId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lessonGroupId])

    const columns = [{
        key: 'rowNumber',
        title: '#',
        dataIndex: 'rowNumber'
    },{
        key: 'groupNameRu',
        title: 'Группа уроков',
        dataIndex: 'groupNameRu'
    }, {
        key: 'nameRu',
        title: 'Название на русском',
        dataIndex: 'nameRu'
    }, {
        key: 'nameKk',
        title: 'Название на казахском',
        dataIndex: 'nameKk'
    }, {
        key: 'rank',
        title: 'Порядок',
        dataIndex: 'rank'
    }, {
        key: 'deleted',
        title: 'Удалено',
        dataIndex: 'deleted',
        render: deleted => deleted ? <Tag color={token.Tag.deletedColor}>Удалено</Tag> : null
    }, {
        key: 'x',
        title: 'Действия',
        dataIndex: '',
        render: row => (
            <Space>
                <Tooltip
                    title='Редактировать'
                    placement='bottom'
                    mouseEnterDelay={0.7}
                >
                    <Button onClick={() => editButtonClick(row)}>
                        <EditOutlined />
                    </Button>
                </Tooltip>
                <Tooltip
                    title='Упражнения'
                    placement='bottom'
                    mouseEnterDelay={0.7}
                >
                    <Button onClick={() => exerciseButtonClick(row)}>
                        <CarryOutOutlined />
                    </Button>
                </Tooltip>
            </Space>
        )
    }]

    const addButtonClick = () => {
        navigate('/admin/lessonEditor/0')
    }

    const editButtonClick = lesson => {
        navigate('/admin/lessonEditor/' + lesson.id)
    }

    const exerciseButtonClick = lesson => {
        navigate('/admin/exercise/' + lesson.id)
    }

    const onLessonGroupChange = value => {
        const params = {}
        searchParams.forEach((value, key) => {
            params[key] = value
        })
        if(value) {
            params.lessonGroupId = value
        } else {
            delete params.lessonGroupId
            setLessonGroup(null)
        }
        setSearchParams(params)
    }

    return (
        <>
            {notificationContextHolder}
            <Space style={{ marginBottom: 10 }}>
                <Select
                    options={lessonGroupOptions}
                    placeholder="Выберите группу уроков"
                    onChange={onLessonGroupChange}
                    value={lessonGroup}
                    allowClear={true}
                />
                <Button 
                    onClick={addButtonClick}>
                    Добавить урок
                </Button>
            </Space>
            <CustomTable
                columns={columns} 
                loading={loading}
                dataSource={lessons}
            />
        </>
    )
}