import { Modal } from 'antd'
import { useContext } from 'react'
import { AdminExerciseContext } from '../../context/adminExercise/adminExerciseContext'
import { AdminExerciseDialogForm } from './AdminExerciseDialogForm'
import { AdminExerciseOrderForm } from './AdminExerciseOrderForm'
import { AdminExerciseTestTaskForm } from './AdminExerciseTestTaskForm'
import { AdminExerciseWordForm } from './AdminExerciseWordForm'

export const AdminExerciseModal = ({updateLessonExercises}) => {
    
    const {modalOpen, setModalOpen, exercise} = useContext(AdminExerciseContext)
    const modalWidth = window.innerWidth * 0.8

    return (
        <Modal
            title="Редактор упражнений"
            okText="Сохранить"
            cancelText="Отмена"
            open={modalOpen}
            okButtonProps={{form:'admin-exercise-form', key: 'submit', htmlType: 'submit', ghost: true}}
            onCancel={() => setModalOpen(false)}
            width={modalWidth}
        >
            {
                exercise?.exerciseType === 'TEST_TASK' ? <AdminExerciseTestTaskForm updateLessonExercises={updateLessonExercises} /> : null
            }
            {
                exercise?.exerciseType === 'WORD' ? <AdminExerciseWordForm updateLessonExercises={updateLessonExercises} /> : null
            }
            {
                exercise?.exerciseType === 'ORDER' ? <AdminExerciseOrderForm updateLessonExercises={updateLessonExercises} /> : null
            }
            {
                exercise?.exerciseType === 'DIALOG' ? <AdminExerciseDialogForm updateLessonExercises={updateLessonExercises} /> : null
            }
        </Modal>
    )
}