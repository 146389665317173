import { useContext } from 'react'
import { Row, Col, Button } from 'antd'
import { AuthContext } from '../context/auth/authContext'
import { SoundOutlined } from '@ant-design/icons'
import AudioRecorder from '../component/AudioRecorder'
import { NewAudioRecorder } from '../component/NewAudioRecorder'

export const TestPage = () => {

    const {vault} = useContext(AuthContext)

    const playMusic = () => {
        console.log('playMusic')
        const audio = new Audio('https://samplelib.com/lib/preview/mp3/sample-3s.mp3')
        audio.play();
    }

    return (
        <Row style={{paddingTop: 20}}>
            <Col span={12} offset={6}>
                { vault ? vault.sub : 'No vault'}
                <br/>
                { vault && vault.exp ? (new Date(vault.exp * 1000)).toString() : 'No exp date' }
                <br /><br />
                <Button type='primary' onClick={playMusic} ghost>
                    <SoundOutlined />
                    Play music
                </Button>
                <br /><br />
                <AudioRecorder />
                <br /><br />
                Текст для озвучки 1&nbsp;<NewAudioRecorder duration={5000} />
                <br/>
                Текст для озвучки 2&nbsp;<NewAudioRecorder duration={5000} />
            </Col>
        </Row>
    )

}