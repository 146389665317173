import { Alert, Breadcrumb, Button, Form, Input, InputNumber, notification, Select, Space, Switch, Tabs, Tag, theme } from 'antd'
import { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams, useNavigate } from 'react-router-dom'
import { Container } from '../../component/Container'
import { Loading } from '../../component/Loading'
import api from '../../context/auth/AuthState'
import showErrorNotification from '../../helper/showErrorNotification'
import MDEditor from '@uiw/react-md-editor'
import showSavedNotification from '../../helper/showSavedNotification'
import { CarryOutOutlined } from '@ant-design/icons'

export const AdminLessonEditor = () => {

    const id = +(useParams().id)
    const isNew = id === 0;
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState(false)
    const [breadcrumbs, setBreadcrumbs] = useState([])
    const [form] = Form.useForm()
    const [lessonGroupOptions, setLessonGroupOptions] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const {token} = theme.useToken()
    const [notificationApi, notificationContextHolder] = notification.useNotification()

    let activeKey = searchParams.get('tab')
    if(!activeKey) {
        activeKey = 'content'
    }

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    }

    useEffect(() => {
        const firstBreadcrumb = {
            title: <Link to="/admin?tab=lesson">Уроки</Link>
        }
        const getLesson = () => {
            if(isNew) {
                form.setFieldsValue({deleted: false})
                return
            }
            setLoading(true)
            api.get(`/admin/lesson/${id}`).then(response => {
                const data = response.data
                setBreadcrumbs([firstBreadcrumb, {
                    title: <Link to={`/admin?tab=lesson&lessonGroupId=${data.lessonGroup.id}`}>{data.lessonGroup.ru}</Link>
                },{
                    title: data.nameRu + ' (' + data.nameKk + ')'
                }])
                form.setFieldsValue(data)
            }).catch(e => {
                showErrorNotification(notificationApi, e)
            }).finally(() => {
                setLoading(false)
            })
        }
        const getLessonGroups = () => {
            api.get('/admin/lesson-group').then(response => {
                const options = response.data.map(x => ({
                    value: x.id,
                    label: x.ru
                }))
                setLessonGroupOptions(options)
            }).catch(e => {
                showErrorNotification(notificationApi, e)
            })
        }
        setBreadcrumbs([firstBreadcrumb])
        getLessonGroups()
        getLesson()
    }, [id, isNew, form, notificationApi])

    const leftTabBar = (
        <Container>
            <Tag color={token.Editor.tagColor}>Редактор</Tag>
        </Container>
    )

    const rightTabBar = (
        <Space style={{padding: 10}}>
            {
                hasErrors ? 
                    <Alert message="Есть ошибки" type="error" showIcon />
                    :
                    null
            }
            <Button
                htmlType="submit"
                type="primary"
                ghost
            >
                Сохранить
            </Button>
        </Space>
    )

    const saveLesson = values => {
        setHasErrors(false)
        const isNew = id === 0
        values.id = isNew ? null : id
        api.put('/admin/lesson', values).then(response => {
            showSavedNotification(notificationApi)
            if(isNew) {
                navigate(`/admin/lessonEditor/${response.data.id}`, {replace: true});
            }
        }).catch(e => {
            showErrorNotification(notificationApi, e)
        })
    }

    const onFormFailed = () => {
        setHasErrors(true)
    }

    const onTabChange = activeKey => {
        setSearchParams({tab: activeKey})
    }

    const tabItems = [{
        key: 'general',
        label: 'Общее',
        forceRender: true,
        children: (
            <>
                <Form.Item 
                    name={['lessonGroup', 'id']}
                    label="Группа уроков"
                    rules={[
                        { required: true, message: 'Укажите название на русском' }
                    ]}
                >
                    <Select
                        options={lessonGroupOptions}
                    />
                </Form.Item>
                <Form.Item 
                    name="nameRu"
                    label="Название на русском"
                    rules={[
                        { required: true, message: 'Укажите название на русском' }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item 
                    name="nameKk"
                    label="Название на казахском"
                    rules={[
                        { required: true, message: 'Укажите название на казахском' }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Ранг для сортировки"
                    name="rank"
                    rules={[{required: true, message: 'Введите ранг для сортировки'}]}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item 
                    label="Отметка об удалении"
                    name="deleted"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
            </>
        )
    }, {
        key: 'content',
        label: 'Контент',
        forceRender: true,
        children: (
            <Form.Item
                name="content"
                label=""
                wrapperCol={{ span: 24 }}
                rules={[
                    { required: true, message: 'Укажите контент' }
                ]}
            >
                <MDEditor
                    height={450}
                    data-color-mode={token.Editor.themeMode}
                />
            </Form.Item>
        )
    }]

    const formFragment = (
        <Form {...formItemLayout}
            form={form}
            onFinish={saveLesson}
            onFinishFailed={onFormFailed}
        >
            <Tabs
                tabBarExtraContent={{left: leftTabBar, right: rightTabBar}}
                items={tabItems}
                activeKey={activeKey}
                onChange={onTabChange}
            />
        </Form>
    )

    return (
        <Container>
            {notificationContextHolder}
            <Breadcrumb items={breadcrumbs} />
            {
                loading ?
                    <Loading />
                :
                    <>
                        {formFragment}
                        <div align="right">
                            <Button type="link">
                                <CarryOutOutlined />
                                &nbsp;
                                <Link to={'/admin/exercise/' + id}>
                                    Перейти к упражнениям
                                </Link>
                            </Button>
                        </div>
                    </>
            }
        </Container>
    )
}