import { getSunrise, getSunset } from 'sunrise-sunset-js'
import { KAZAKHSTAN_CENTER } from '../constant/Constants'

export default function updateTheme(darkThemeMode, setTheme, setGeolocationError) {
    console.log('updateTheme', darkThemeMode)
    if(darkThemeMode === 'ALWAYS') {
        setTheme('DARK')
    } else if(darkThemeMode === 'AUTO') {
        determineTheme(setTheme, setGeolocationError)
    } else {
        setTheme(null)
    }
}

const determineTheme = (setTheme, setGeolocationError) => {
    if (!navigator.geolocation) {
        console.log('Geolocation is not supported by this browser')
        determineThemeByCoords(KAZAKHSTAN_CENTER, setTheme)
        if(setGeolocationError) {
            setGeolocationError('NOT_SUPPORTED')
        }
        return
    }
    navigator.geolocation.getCurrentPosition(position => {
        determineThemeByCoords(position.coords, setTheme)
        if(setGeolocationError) {
            setGeolocationError(null)
        }
    }, error => {
        console.log('geolocation error code', error.code)
        console.log('geolocation error message', error.message)
        determineThemeByCoords(KAZAKHSTAN_CENTER, setTheme)
        if(setGeolocationError) {
            setGeolocationError('IS_NOT_AVAILABLE')
        }
    })
}

const determineThemeByCoords = (coords, setTheme) => {
    const now = new Date()
    const sunrise = getSunrise(coords.latitude, coords.longitude)
    const sunset = getSunset(coords.latitude, coords.longitude)
    if(now < sunrise || now > sunset) {
        setTheme('DARK')
    }
}

