import { useContext } from 'react'
import { Row, Col, Form, Button, Input, theme } from 'antd'
import { GoogleOutlined, UserAddOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import classes from './Login.module.css'
import { AuthContext } from '../context/auth/authContext'
import { LoginOutlined, LoadingOutlined } from '@ant-design/icons'
import FeatureFlagService from '../service/FeatureFlagService'

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
}

const googleAuth = () => {
    window.location = '/services/open-api/google/auth'
}

export const Login = () => {
    const {token} = theme.useToken()
    const {login, sending} = useContext(AuthContext)
    let defaultEmail = ''
    let defaultPassword = ''
    if(FeatureFlagService.needToFillLoginAndPassword()) {
        defaultEmail = 'n.kopeyev@gmail.com'
        defaultPassword = 'qwerty1'
    }
    return (
        <>
            <Row style={{paddingTop: 20}}>
                <Col span={12} offset={6}>
                    <div align="center">
                        <Button 
                            icon={<GoogleOutlined />}
                            onClick={googleAuth}
                        >
                            Войти через Google
                        </Button>
                    </div>
                </Col>
            </Row>
            <Form {...formItemLayout}
                name="basic"
                initialValues={{ remember: true, login: defaultEmail, password: defaultPassword }}
                onFinish={login}
                autoComplete="off"
                className={classes.loginForm}
                style={{background: token.Login.colorBgBody}}
            >
                <Form.Item
                    label="Логин или e-mail"
                    name="login"
                    rules={[{ required: true, message: 'Введите логин или e-mail' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Пароль"
                    name="password"
                    rules={[{ required: true, message: 'Введите пароль' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item wrapperCol={{ md: {offset: 8}}}>
                    <Button 
                        icon={sending ? <LoadingOutlined /> : <LoginOutlined /> }
                        type="primary" 
                        htmlType="submit"
                        disabled={sending}
                        ghost
                    >
                        Войти
                    </Button>
                    <div className={classes.termsAndPolicy}>
                        Используя вход через Google, E-mail или логин,<br />
                        вы соглашаетесь с <Link to={'/terms-of-use'}>Условиями использования</Link><br />
                        и <Link to={'/privacy-policy'}>Политикой конфиденциальности</Link>
                    </div>
                </Form.Item>
            </Form>

            <div align="center" style={{paddingTop: 10}}>
                <Button type="link">
                    <UserAddOutlined /> <Link to={'/register'}>Зарегистрироваться</Link>
                </Button>
            </div>
        </>
    )
}