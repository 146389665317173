import axios from 'axios'
import { Col, Collapse, Row, Table, theme } from 'antd'
import { useEffect, useState } from 'react'
import { CheckCircleTwoTone, ClockCircleTwoTone, MinusCircleTwoTone } from '@ant-design/icons'
import LocalStorageService from '../../service/LocalStorageService'
import api from '../../context/auth/AuthState'
import { Link } from 'react-router-dom'
import { Level } from '../../component/Level'

export const LessonList = () => {

    const [lessonGroups, setLessonGroups] = useState([])
    const [groupOpenKeys, setGroupOpenKeys] = useState([])
    const {token} = theme.useToken()

    useEffect(() => {
        try {
            setGroupOpenKeys(LocalStorageService.readGroupOpenKeys())
        } catch (e) {
            console.log(e)
        }
        const lessonPromise = axios.get('/services/open-api/lesson')
        const lessonKnowledgePromise = api.get('lesson-knowledge')
        Promise.all([lessonPromise, lessonKnowledgePromise]).then(response => {
            const lessonGroups = response[0].data
            const lessonKnowledges = response[1].data
            const lessonKnowledgeMap = {}
            lessonKnowledges.forEach(lessonKnowledge => {
                lessonKnowledgeMap[lessonKnowledge.lesson.id] = lessonKnowledge
            })
            const now = Date.now()
            lessonGroups.forEach(lessonGroup => {
                lessonGroup.lessons.forEach(lesson => {
                    const lessonKnowledge = lessonKnowledgeMap[lesson.id]
                    if(!lessonKnowledge) {
                        return
                    }
                    lesson.level = lessonKnowledge.level
                    if(lessonKnowledge.passed) {
                        if(Date.parse(lessonKnowledge.nextMilestoneDate) <= now) {
                            lesson.icon = 'repeat'
                        } else {
                            lesson.icon = 'passed'
                        }
                    }
                })
                if(lessonGroup.lessons.some(lesson => lesson.icon === 'repeat')) {
                    lessonGroup.icon = 'repeat'
                } else if (lessonGroup.lessons.every(lesson => lesson.icon === 'passed')) {
                    lessonGroup.icon = 'passed'
                }
            })
            setLessonGroups(lessonGroups)
        })
    }, [])

    const getIcon = icon => {
        switch(icon) {
            case 'passed':
                return (
                    <CheckCircleTwoTone twoToneColor={token.Lesson.passedColor} />
                )
            case 'repeat':
                return (
                    <ClockCircleTwoTone twoToneColor={token.Lesson.repeatColor} />
                )
            default:
                return (
                    <MinusCircleTwoTone twoToneColor={token.Lesson.notPassedColor} />
                )
        }
    }

    const columns = [{
        dataIndex: 'name',
        key: 'name',
        render: (_, record) => (
            <Link to={`/lesson/${record.id}`}>
                {getIcon(record.icon)}
                &nbsp;&nbsp;
                {record.nameRu}
            </Link>
        )
    }, {
        dataIndex: 'level',
        key: 'level',
        render: (_, record) => (
            <Level
                value={record.level}
                count={4}
            />
        )
    }]

    const collapseItems = lessonGroups.map(lessonGroup => {
        return {
            key: `lessonGroup${lessonGroup.id}`,
            label:
                (
                    <>
                        {getIcon(lessonGroup.icon)}
                        &nbsp;&nbsp;
                        {lessonGroup.ru}
                    </>
                ),
            children: (
                <Table
                    columns={columns}
                    dataSource={lessonGroup.lessons}
                    showHeader={false}
                    pagination={false}
                    rowKey="id"
                />
            )
        }
    })

    const onChange = value => {
        console.log('onChange', value)
        setGroupOpenKeys(value)
        LocalStorageService.saveGroupOpenKeys(value)
    }

    return (
        <Row style={{paddingTop: 20}}>
            <Col span={12} offset={6}>
                <Collapse
                    items={collapseItems}
                    onChange={onChange}
                    activeKey={groupOpenKeys}
                    expandIconPosition="end"
                />
            </Col>
        </Row>
    )

}