import { Spin } from 'antd'
import classes from './Loading.module.css'

export const Loading = () => {
    return (
        <div className={classes.loading}>
            <Spin 
                tip="Идет загрузка..."
                size="large"
            />
        </div>
    )
}