import { Form, Input, Button, Alert, Row, Col } from 'antd'
import { useContext } from 'react'
import { RegisterContext } from '../context/register/registerContext'
import { SendOutlined, LoadingOutlined } from '@ant-design/icons'

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
}

const buttonItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { xs: {span: 8}, sm: {span: 8, offset: 8} },
}

export const Register = () => {
    const {register, flagSuccess, flagError, flagForm, errorMessage, errorType, sending} = useContext(RegisterContext)

    const registeredFragment = flagSuccess ? (
        <Row style={{paddingTop: 20}}>
            <Col span={12} offset={6}>
                <Alert 
                    message="На ваш e-mail отправлено письмо для активации учетной записи" 
                    type="success" 
                    showIcon />
            </Col>
        </Row>
    ) : null

    const errorFragment = flagError ? (
        <Row style={{paddingTop: 20}}>
            <Col span={12} offset={6}>
                <Alert 
                    message={errorMessage} 
                    type={errorType} 
                    showIcon />
            </Col>
        </Row>
    ) : null

    const formFragment = flagForm ? (
        <Form {...formItemLayout}
                style={{padding: '20px'}}
                onFinish={register}
            >
            <Form.Item 
                name={['user', 'login']} 
                label="Логин" 
                hasFeedback
                rules={[
                    { required: true, message: 'Укажите логин' }
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item 
                name={['user', 'email']} 
                label="E-mail" 
                hasFeedback
                rules={[
                    { type: 'email', message: 'Указан неверный e-mail' }, 
                    { required: true, message: 'Укажите e-mail' }
                ]}>
                <Input />
            </Form.Item>
            <Form.Item 
                name={['user', 'lastName']} 
                label="Фамилия"
                hasFeedback
                rules={[
                    { required: true, message: 'Укажите фамилию' }
                ]}>
                <Input />
            </Form.Item>
            <Form.Item 
                name={['user', 'firstName']} 
                label="Имя" 
                hasFeedback
                rules={[
                    { required: true, message: 'Укажите имя' }
                ]}>
                <Input />
            </Form.Item>
            <Form.Item 
                name={['user', 'middleName']}
                label="Отчество"
                hasFeedback>
                <Input />
            </Form.Item>
            <Form.Item
                name={['user', 'password']}
                label="Пароль"
                rules={[{
                    required: true,
                    message: 'Укажите пароль'
                }, {
                    min: 7,
                    message: "Минимальная длина пароля 7 символов"
                }]}
                hasFeedback
            >
                <Input.Password />
            </Form.Item>
            <Form.Item {...buttonItemLayout}>
                <Button 
                    icon={sending ? <LoadingOutlined /> : <SendOutlined /> }
                    type="primary" 
                    htmlType="submit"
                    disabled={sending}
                    ghost
                >
                    Зарегистрироваться
                </Button>
            </Form.Item>
        </Form>
    ) : null

    return (
        <>
            { registeredFragment }
            { errorFragment }
            { formFragment }
        </>
    )
}