import { ERRORS } from '../constant/Errors'

export default function showError (notification, error) {
    if(error?.response?.status === 400) {
        const data = error.response.data
        if(data.type === 'ERROR') {
            const message = ERRORS[data.message]
            if(message) {
                notification['error']({
                    message: 'Произошла ошибка: ' + message,
                    description: 'Попробуйте еще раз...',
                    placement: 'top'
                })
                return
            }
        }
    }
    notification['error']({
        message: 'Произошла ошибка',
        description: 'Попробуйте еще раз...',
        placement: 'top'
    })
}