import { Row, Col, theme } from 'antd'

export const TermsOfUse = () => {
    const {token} = theme.useToken()
    return (
        <Row style={{paddingTop: 20, color: token.Text.color}}>
            <Col span={12} offset={6}>
                <div align="center">
                    <h3>Условия использования</h3>
                </div>
                <p>Добро пожаловать на сайт salemetsiz.be, созданный для помощи в изучении казахского языка!</p>
                <p>Сайт создан Копеевым Найманом (далее автор или владелец сайта).</p>
                <p>В настоящих Условиях изложены условия использования, при которых вы можете использовать веб-сайт и возможностями, которые он предлагает.</p>
                <p>Сайт предлагает теорию в виде уроков, практические упражнения, тематические диалоги и возможности по расширению словарного запаса.</p>
                <p>Получая доступ к данному веб-сайту или используя его, вы подтверждаете, что прочитали, поняли и согласны с настоящими Условиями.</p>
                <p>Учебные материалы не содержат ненормативного контента и не имеют возрастных ограничений.</p>
                <p>Материалы сайт полностью бесплатны для личного использования, но любое коммерческое использование материалов, их фрагментов, функциональности сайта запрещено.</p>
                <p>Владелец сайта может без предварительного уведомления изменить или ограничить фукнциональность сайта.</p>
                <p>Владелец сайта может навсегда или временно прекратить работу сайта без уведомления и отвественности по любой причине или без причины.</p>
                <p>Владелец сайта может навсегда или временно прекратить или приостановить ваш доступ к сайту без уведомления и ответственности по любой причине, в том числе, если по его единоличному решению вы нарушите какое-либо положение настоящих Условий или правил.</p>
                <p>Автор сайта не дает каких-либо гарантии в улучшении навыков казахского языка.</p>
                <p>Вы можете прекратить использование сайт в любое время.</p>
                <p>Владелец сайта оставляет за собой право изменять эти условия время от времени по собственному усмотрению. Поэтому вы должны периодически просматривать эту страницу.</p>
                <p>При существенных изменениях в условиях вы можете быть уведомлены по средством электронной почты.</p>
                <p>Ваше дальнейшее использование Сайта после любого изменения Условий означает ваше согласие с новыми Условиями. </p>
                <p>Если вы не согласны c каким-либо из Условий, не используйте или не пользуйтесь (или не открывайте) веб-сайт.</p>
                <p>На сайте запрещается использование нецензурной лексики и оскорбления в любой форме.</p>
            </Col>
        </Row>
    )
}