import { useReducer } from 'react'
import { SET_DARK_THEME_MODE, SET_GEOLOCATION_ERROR, SET_THEME } from '../types'
import { UserPrefContext } from './userPrefContext'
import { userPrefReducer } from './userPrefReducer'

export const UserPrefState = ({children}) => {
    const initialState = {
        darkThemeMode: 'OFF',
        theme: null,
        geolocationError: null
    }

    const [state, dispatch] = useReducer(userPrefReducer, initialState)

    const setDarkThemeMode = value => dispatch({type: SET_DARK_THEME_MODE, darkThemeMode: value})
    const setTheme = value => dispatch({type: SET_THEME, theme: value})
    const setGeolocationError = value => dispatch({type: SET_GEOLOCATION_ERROR, geolocationError: value})

    const {darkThemeMode, theme, geolocationError} = state

    return (
        <UserPrefContext.Provider value={{
            darkThemeMode, setDarkThemeMode,
            theme, setTheme,
            geolocationError, setGeolocationError
        }}>
            { children }
        </UserPrefContext.Provider>
    )
}