import { useReducer } from 'react'
import { SET_LESSON_KNOWLEDGE_CHECK, SET_LESSON_KNOWLEDGE_CHECK_COMPLETED, SET_LESSON_KNOWLEDGE_CHECK_EXERCISE, SET_LESSON_KNOWLEDGE_CHECK_SHOW_NEXT_BUTTON } from '../types'
import { LessonKnowledgeCheckContext } from './lessonKnowledgeCheckContext'
import { lessonKnowledgeCheckReducer } from './lessonKnowledgeCheckReducer'

export const LessonKnowledgeCheckState = ({children}) => {
    const initialState = {
        lessonKnowledgeCheck: null,
        lessonKnowledgeCheckCompleted: false,
        lessonKnowledgeCheckExercise: 0,
        lessonKnowledgeCheckShowNextButton: false
    }

    const [state, dispatch] = useReducer(lessonKnowledgeCheckReducer, initialState)

    const setLessonKnowledgeCheck = value => dispatch({type: SET_LESSON_KNOWLEDGE_CHECK, lessonKnowledgeCheck: value})
    const setLessonKnowledgeCheckCompleted = value => dispatch({type: SET_LESSON_KNOWLEDGE_CHECK_COMPLETED, lessonKnowledgeCheckCompleted: value})
    const setLessonKnowledgeCheckExercise = value => dispatch({type: SET_LESSON_KNOWLEDGE_CHECK_EXERCISE, lessonKnowledgeCheckExercise: value})
    const setLessonKnowledgeCheckShowNextButton = value => dispatch({type: SET_LESSON_KNOWLEDGE_CHECK_SHOW_NEXT_BUTTON, lessonKnowledgeCheckShowNextButton: value})

    const {lessonKnowledgeCheck, lessonKnowledgeCheckCompleted, lessonKnowledgeCheckExercise, lessonKnowledgeCheckShowNextButton} = state

    return (
        <LessonKnowledgeCheckContext.Provider value={{
            lessonKnowledgeCheck, setLessonKnowledgeCheck,
            lessonKnowledgeCheckCompleted, setLessonKnowledgeCheckCompleted,
            lessonKnowledgeCheckExercise, setLessonKnowledgeCheckExercise,
            lessonKnowledgeCheckShowNextButton, setLessonKnowledgeCheckShowNextButton
        }}>
            { children }
        </LessonKnowledgeCheckContext.Provider>
    )
}