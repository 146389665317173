import { Alert, Button, Form, Input, notification, Radio, Space } from 'antd'
import { useContext, useEffect, useState } from 'react'
import api from '../../context/auth/AuthState'
import { LessonKnowledgeCheckContext } from '../../context/lessonKnowledgeCheck/lessonKnowledgeCheckContext'
import showErrorNotification from '../../helper/showErrorNotification'
import ShuffleHelper from '../../helper/ShuffleHelper'
import { CheckOutlined } from '@ant-design/icons'

export const ExerciseTestTaskSingleChoice = () => {

    const {
        lessonKnowledgeCheckExercise,
        setLessonKnowledgeCheckShowNextButton
    } = useContext(LessonKnowledgeCheckContext)
    const [form] = Form.useForm()
    const [formDisabled, setFormDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(null)
    const [notificationApi, notificationContextHolder] = notification.useNotification()

    const reset = () => {
        form.resetFields()
        setFormDisabled(false)
        setStatus(null)
        setLoading(false)
        setLessonKnowledgeCheckShowNextButton(false)
    }

    useEffect(() => {
        reset()
        api.get(`/test-task/${lessonKnowledgeCheckExercise.exercise.testTask.id}/items`)
        .then(response => {
            let items = response.data
            items = ShuffleHelper.stableShuffle(items, lessonKnowledgeCheckExercise.id)
            items.forEach(item => {
                item.checked = false
            })
            form.setFieldsValue({
                items: items
            })
        })
        .catch(e => {
            showErrorNotification(notificationApi, e)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lessonKnowledgeCheckExercise])

    const check = values => {
        const items = values.items
                .filter(item => item.checked)
        if(!items.length) {
            setStatus('NOT_FILLED')
            return
        }
        const requestData = {
            testTaskItemId: items[0].id
        }
        setLoading(true)
        setFormDisabled(true)
        setStatus(null)
        api.put(`/lesson-knowledge-check-exercise/${lessonKnowledgeCheckExercise.id}/test-task/single-choice`, requestData)
        .then(response => {
            if(response.data.result === 'ACCEPTED') {
                setStatus('ACCEPTED')
                setLessonKnowledgeCheckShowNextButton(true)
            } else if(response.data.result === 'NOT_ACCEPTED') {
                setStatus('NOT_ACCEPTED')
                setFormDisabled(false)
            } else {
                showErrorNotification()
                setFormDisabled(false)
            }
            setLoading(false)
        })
        .catch(e => {
            showErrorNotification(notificationApi, e)
            setLoading(false)
            setFormDisabled(false)
        })
    }

    const onFlagCorrectChange = (fields, currentField) => {
        fields.forEach(field => {
            form.setFieldValue(['items', field.name, 'checked'], field.name === currentField.name)
        })
    }

    return (
        <>
            {notificationContextHolder}
            {lessonKnowledgeCheckExercise.exercise.testTask.content}
            <br /><br />
            <Form
                id="exercise-test-task-single-choice-form"
                name="control-ref"
                form={form}
                onFinish={check}
                disabled={formDisabled}
             >
                <Form.List
                    name="items"
                >
                    {
                        (fields) => (
                            <div>
                                {
                                    fields.map((field, index) => (
                                        <Space.Compact key={'item' + index} block>
                                            <Form.Item
                                                 name={[field.name, 'checked']}
                                                valuePropName="checked"
                                            >
                                                <Radio
                                                    onChange={() => onFlagCorrectChange(fields, field)}
                                                />
                                            </Form.Item>
                                            &nbsp;&nbsp;
                                            <Form.Item name={[field.name, 'content']}>
                                                <Input.TextArea
                                                    autoSize={true}
                                                    variant="borderless"
                                                    readOnly
                                                    cols={1000}
                                                />
                                            </Form.Item>
                                        </Space.Compact>
                                    ))
                                }
                            </div>
                        )
                    }
                </Form.List>
                {
                    status === 'NOT_FILLED'
                    ? (
                        <Form.Item>
                            <Alert message="Выберите ваш вариант" type="warning" showIcon />
                        </Form.Item>
                    ) : null
                }
                {
                    status === 'ACCEPTED'
                    ? (
                        <Form.Item>
                            <Alert message="Ответ принят!" type="success" showIcon />
                        </Form.Item>
                    ) : null
                }
                {
                    status === 'NOT_ACCEPTED'
                    ? (
                        <Form.Item>
                            <Alert message="Ответ не принят. Попробуйте другой вариант." type="warning" showIcon />
                        </Form.Item>
                    ) : null
                }
                {
                    status !== 'ACCEPTED'
                    ? (
                        <Form.Item>
                            <center>
                                <Button
                                    icon={<CheckOutlined />}
                                    htmlType="submit"
                                    disabled={formDisabled}
                                    loading={loading}
                                >
                                    Проверить
                                </Button>
                            </center>
                        </Form.Item>
                    ) : null
                }
            </Form>
        </>
    )
}