import { useReducer } from 'react'
import { ActivationContext } from './activationContext'
import { activationReducer } from './activationReducer'
import { SET_SENDING, ACTIVATION_SUCCESS, ACTIVATION_ERROR } from '../types'
import axios from 'axios'

export const ActivationState = ({children}) => {
    const initialState = {
        sending: false,
        flagSuccess: false,
        flagError: false,
        errorMessage: ''
    }

    const activate = async key => {
        setSending()
        axios.put(`/services/open-api/registration/activate/${key}`)
            .then(res => {
                if(res.data.code === 'SUCCESS') {
                    activationSuccess()
                } else {
                    activationError()
                }
            })
            .catch(() => {
                activationError()
            })
    }

    const setSending = () => dispatch({type: SET_SENDING})
    const activationSuccess = () => dispatch({type: ACTIVATION_SUCCESS})
    const activationError = () => dispatch({type: ACTIVATION_ERROR})

    const [state, dispatch] = useReducer(activationReducer, initialState)
    const {sending, flagSuccess, flagError} = state

    return (
        <ActivationContext.Provider value={{activate, sending, flagSuccess, flagError}}>
            { children }
        </ActivationContext.Provider>
    )
}