import { ExerciseTestTask } from '../exercise/ExerciseTestTask'
import { ExerciseWord } from '../exercise/ExerciseWord'
import { ExerciseDialog } from '../exercise/ExerciseDialog'
import { ExerciseOrder } from '../exercise/ExerciseOrder'
import { useContext } from 'react'
import { LessonKnowledgeCheckContext } from '../../context/lessonKnowledgeCheck/lessonKnowledgeCheckContext'

export const Exercise = () => {
    
    const {lessonKnowledgeCheckExercise} = useContext(LessonKnowledgeCheckContext)

    switch(lessonKnowledgeCheckExercise.exercise.exerciseType) {
        case 'TEST_TASK':
            return (
                <ExerciseTestTask />
            )
        case 'WORD':
            return (
                <ExerciseWord />
            )
        case 'DIALOG':
            return (
                <ExerciseDialog />
            )
        case 'ORDER':
            return (
                <ExerciseOrder />
            )
        default:
            return (
                <div>Unknown exercise type</div>
            )
    }


}