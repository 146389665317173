class TokenService {

    setUser(user) {
        localStorage.setItem('USER', JSON.stringify(user))
    }

    getUser() {
        return JSON.parse(localStorage.getItem('USER'))
    }

    getAccessToken() {
        return this.getUser()?.accessToken
    }

    getRefreshToken() {
        return this.getUser()?.refreshToken
    }

    removeUser() {
        localStorage.removeItem('USER')
    }
}

export default new TokenService()