class LocalStorageService {

    saveGroupOpenKeys(keys) {
        localStorage.setItem('GROUP_OPEN_KEYS', JSON.stringify(keys))
    }

    readGroupOpenKeys() {
        try {
            return JSON.parse(localStorage.getItem('GROUP_OPEN_KEYS'))
        } catch (e) {
            console.log(e)
            return []
        }
        
    }

}

export default new LocalStorageService()