import classes from './OrderableSentence.module.css'
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import { useState } from 'react'
import { arrayMoveImmutable } from 'array-move'

export const OrderableSentence = ({value, onChange}) => {
    const [items, setItems] = useState(value || [])
    console.log('items', items)

    if(!items?.length) {
        return null
    }
    
    const onSortEnd = (oldIndex, newIndex) => {
        console.log('onSortEnd', oldIndex, newIndex)
        const newArray = arrayMoveImmutable(items, oldIndex, newIndex)
        setItems(newArray)
        onChange(newArray)
    }

    const removeParentheses = value => {
        return value.substring(1, value.length - 1)
    }

    return (
        <SortableList
            className={classes.list}
            draggedItemClassName={classes.dragged}
            onSortEnd={onSortEnd}
        >
            {
                items.map(item => (
                    item.startsWith("{") ? (
                        <SortableItem key={item}>
                            <div className={classes.item}>
                                <SortableKnob>
                                    <div>{removeParentheses(item)}</div>
                                </SortableKnob>
                            </div>
                        </SortableItem>
                    ) : (
                        <SortableItem key={item}>
                            <div className={[classes.item, classes.fixed].join(' ')}>
                                {item}
                            </div>
                        </SortableItem>
                    )
                ))
            }
        </SortableList>
    )
}