const handlers = {
    SET_SENDING: state => ({...state, sending: true}),
    UNSET_SENDING: state => ({...state, sending: false}),
    LOGIN: (state, action) => ({...state, sending: false, vault: action.payload}),
    DEFAULT: state => state
}

export const authReducer = (state, action) => {
    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}