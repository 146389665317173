import { Alert, Form, notification, Radio } from 'antd'
import { useContext, useEffect } from 'react'
import api from '../../context/auth/AuthState'
import showErrorNotification from '../../helper/showErrorNotification'
import { UserPrefContext } from '../../context/userPref/userPrefContext'
import updateTheme from '../../helper/updateTheme'

export const ProfileDesign = () => {
    const {
        setDarkThemeMode, setTheme,
        geolocationError, setGeolocationError
    } = useContext(UserPrefContext)
    const [notificationApi, notificationContextHolder] = notification.useNotification()

    useEffect(() => {
        setGeolocationError(null)
        // eslint-disable-next-line
    }, [])

    const onDarkThemeChange = event => {
        api.patch('/user-pref', {darkThemeMode: event.target.value})
        .then(() => {
            const darkThemeMode = event.target.value
            setDarkThemeMode(darkThemeMode)
            updateTheme(darkThemeMode, setTheme, setGeolocationError)
        })
        .catch(e => {
            showErrorNotification(notificationApi, e)
        })
    }

    return (
        <Form>
            { notificationContextHolder }
            <Form.Item
                label="Темная тема"
                name="darkTheme"
            >
                <Radio.Group
                    onChange={onDarkThemeChange}
                    buttonStyle="solid">
                    <Radio.Button value="ALWAYS">Всегда</Radio.Button>
                    <Radio.Button value="AUTO">Автоматически</Radio.Button>
                    <Radio.Button value="OFF">Отключена</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {
                geolocationError === 'NOT_SUPPORTED' ?
                (
                    <Alert message="Ваш браузер не поддерживает геолокацию. Геолокация нужна для более точного переключения темы" type="warning" />
                ) : null
            }
            {
                geolocationError === 'IS_NOT_AVAILABLE' ?
                (
                    <Alert message="Геолокация недоступна. Геолокация нужна для более точного переключения темы" type="warning" />
                ) : null
            }
        </Form>
    )
}