import { Button, Space, Modal, Form, Input, InputNumber, Switch, Tag, theme, notification } from 'antd'
import { useEffect, useState } from 'react'
import { CustomTable } from '../../component/CustomTable'
import api from '../../context/auth/AuthState'
import showErrorNotification from '../../helper/showErrorNotification'
import showSavedNotification from '../../helper/showSavedNotification'
import { EditOutlined } from '@ant-design/icons'

export const AdminLessonGroupList = () => {
    
    const [loading, setLoading] = useState(true)
    const [lessonGroups, setLessonGroups] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [form] = Form.useForm()
    const modalWidth = window.innerWidth * 0.8
    const {token} = theme.useToken()
    const [notificationApi, notificationContextHolder] = notification.useNotification()

    const getLessonGroups = () => {
        api.get('/admin/lesson-group').then(response => {
            response.data.map(x => x.key = x.id)
            setLessonGroups(response.data)
        }).catch(e => {
            showErrorNotification(notificationApi, e)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getLessonGroups()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addButtonClick = () => {
        form.resetFields()
        form.setFieldsValue({deleted: false})
        setModalOpen(true)
    }

    const editButtonClick = lessonGroup => {
        form.setFieldsValue(lessonGroup)
        setModalOpen(true)
    }

    const columns = [{
        key: 'ru',
        title: 'Название на русском',
        dataIndex: 'ru'
    }, {
        key: 'kk',
        title: 'Название на казахском',
        dataIndex: 'kk'
    }, {
        key: 'rank',
        title: 'Порядок',
        dataIndex: 'rank'
    }, {
        key: 'deleted',
        title: 'Удалено',
        dataIndex: 'deleted',
        render: deleted => deleted ? <Tag color={token.Tag.deletedColor}>Удалено</Tag> : null
    }, {
        key: 'x',
        title: 'Действия',
        dataIndex: '',
        render: row => (
            <Space>
                <Button
                    onClick={() => editButtonClick(row)}
                >
                    <EditOutlined />
                </Button>
            </Space>
        )
    }]

    const saveLessonGroup = values => {
        api.put('/admin/lesson-group', values).then(() => {
            showSavedNotification(notificationApi)
            getLessonGroups()
            setModalOpen(false)
        }).catch(e => {
            showErrorNotification(notificationApi, e)
        })
    }

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    }

    return (
        <>
            {notificationContextHolder}
            <Space style={{ marginBottom: 10 }}>
                <Button
                    onClick={addButtonClick}
                >
                    Добавить группу уроков
                </Button>
            </Space>
            <CustomTable
                columns={columns}
                loading={loading}
                dataSource={lessonGroups}
                pagination={false}
            />
            <Modal
                title="Создать или изменить группу уроков"
                okText="Сохранить"
                cancelText="Отмена"
                open={modalOpen}
                okButtonProps={{form:'lesson-group-form', key: 'submit', htmlType: 'submit', ghost: true}}
                onCancel={() => setModalOpen(false)}
                width={modalWidth}
            >
               <Form
                    id="lesson-group-form"
                    {...formItemLayout}
                    name="control-ref"
                    form={form}
                    onFinish={saveLessonGroup}
               >
                    <Form.Item
                        label="ID"
                        name="id"
                    >
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        label="Название на русском"
                        name="ru"
                        rules={[{required: true, message: 'Введите наименование на русском'}]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Название на казахском"
                        name="kk"
                        rules={[{required: true, message: 'Введите наименование на казахском'}]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Ранг для сортировки"
                        name="rank"
                        rules={[{required: true, message: 'Введите ранг для сортировки'}]}
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item 
                        label="Отметка об удалении" 
                        name="deleted"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}