export const SET_SENDING = 'SET_SENDING'
export const UNSET_SENDING = 'UNSET_SENDING'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_ERROR = 'REGISTER_ERROR'
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS'
export const ACTIVATION_ERROR = 'ACTIVATION_ERROR'
export const LOGIN = 'LOGIN'
export const SET_CHECKING = 'SET_CHECKING'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const SET_ADMIN_EXERCISE_MODAL_OPEN = 'SET_ADMIN_EXERCISE_MODAL_OPEN'
export const SET_ADMIN_EXERCISE = 'SET_ADMIN_EXERCISE'
export const SET_ADMIN_EXERCISE_LESSON = 'SET_ADMIN_EXERCISE_LESSON'
export const SET_ADMIN_LESSON_EXERCISES = 'SET_ADMIN_LESSON_EXERCISES'
export const SET_LESSON_KNOWLEDGE_CHECK = 'SET_LESSON_KNOWLEDGE_CHECK'
export const SET_LESSON_KNOWLEDGE_CHECK_COMPLETED = 'SET_LESSON_KNOWLEDGE_CHECK_COMPLETED'
export const SET_LESSON_KNOWLEDGE_CHECK_EXERCISE = 'SET_LESSON_KNOWLEDGE_CHECK_EXERCISE'
export const SET_LESSON_KNOWLEDGE_CHECK_SHOW_NEXT_BUTTON = 'SET_LESSON_KNOWLEDGE_CHECK_SHOW_NEXT_BUTTON'
export const SET_DARK_THEME_MODE = 'SET_DARK_THEME_MODE'
export const SET_THEME = 'SET_THEME'
export const SET_GEOLOCATION_ERROR = 'SET_GEOLOCATION_ERROR'