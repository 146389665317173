import { notification, Select } from 'antd'
import { useEffect, useState } from 'react'
import api from '../context/auth/AuthState'
import showErrorNotification from '../helper/showErrorNotification'

export const AdminExerciseDescriptionSelect = ({value, onChange}) => {
    console.log('AdminExerciseDescriptionSelect', value)
    const [descriptionOptions, setDescriptionOptions] = useState([])
    const [notificationApi, notificationContextHolder] = notification.useNotification()

    useEffect(() => {
        if(!value) {
            return
        }
        api.get(`/admin/exercise-description/${value}`)
        .then(response => {
            const description = response.data
            setDescriptionOptions([{
                value: description.id,
                label: description.kk + ' - ' + description.ru
            }])
        })
        .catch(e => {
            showErrorNotification(notificationApi, e)
        })
    }, [value, notificationApi])

    const searchDescription = query => {
        api.get('/admin/exercise-description/search', {
            params: {
                query: query
            }
        })
        .then(response => {
            setDescriptionOptions(response.data.map(description => ({
                value: description.id,
                label: description.kk + ' - ' + description.ru
            })))
        })
        .catch(e => {
            showErrorNotification(notificationApi, e)
        })
    }

    const selectDescription = value => {
        onChange(value);
    }

    return (
        <>
            {notificationContextHolder}
            <Select
                options={descriptionOptions}
                placeholder="Найдите и выберите описание"
                onSearch={searchDescription}
                onSelect={selectDescription}
                filterOption={false}
                defaultValue={value}
                showSearch
            />
        </>
    )
}