import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import MDEditor from '@uiw/react-md-editor'
import { Breadcrumb, notification, Result, Space, Tabs, theme } from 'antd'
import { BulbOutlined, PieChartOutlined, ScheduleOutlined, CloseCircleTwoTone, ReadOutlined } from '@ant-design/icons'
import { LessonKnowledgeCheck } from './LessonKnowledgeCheck'
import { LessonKnowledgeCheckContext } from '../../context/lessonKnowledgeCheck/lessonKnowledgeCheckContext'
import api from '../../context/auth/AuthState'
import showErrorNotification from '../../helper/showErrorNotification'
import { Container } from '../../component/Container'
import useScreenSize from '../../hook/useScreenSize'
import { SM } from '../../constant/Dimensions'

export const LessonContent = () => {

    const id = +(useParams().id)
    const [lesson, setLesson] = useState(null)
    const [activeKey, setActiveKey] = useState('lesson')
    const {setLessonKnowledgeCheck, setLessonKnowledgeCheckExercise} = useContext(LessonKnowledgeCheckContext)
    const {token} = theme.useToken()
    const [notificationApi, notificationContextHolder] = notification.useNotification()
    const screenSize = useScreenSize()

    useEffect(() => {
        if(id) {
            axios.get(`/services/open-api/lesson/${id}`)
                .then(response => {
                    setLesson(response.data)
                })
        } else {
            setLesson(null)
        }
        setActiveKey('lesson')
    }, [id])

    const tabItems = lesson ? [
        {
            label:
                <Space>
                    <BulbOutlined />
                    Урок
                </Space>,
            key: 'lesson',
            children: (
                <div data-color-mode={token.Editor.themeMode}>
                    <MDEditor.Markdown
                        source={lesson.content}
                        style={{
                            background: token.Editor.colorBgBody,
                            color: token.Editor.textColor
                        }}
                    />
                </div>
            )
        }, {
            label:
                <Space>
                    <ScheduleOutlined />
                    Проверка знаний
                </Space>,
            key: 'check',
            children: <LessonKnowledgeCheck lesson={lesson} />
        }, {
            label:
                <Space>
                    <PieChartOutlined />
                    Статистика
                </Space>,
            key: 'statistics',
            children: 'todo statistics'
        }
    ] : null

    const showActiveCheck = lessonId => {
        api.get(`lesson-knowledge-check?lessonId=${lessonId}`)
        .then(response => {
            const check = response.data
            setLessonKnowledgeCheck(check)
            if(check?.checkExercises) {
                setLessonKnowledgeCheckExercise(check.checkExercises[0])
            } else {
                setLessonKnowledgeCheckExercise(null)
            }
        })
        .catch(e => {
            showErrorNotification(notificationApi, e)
        })
    }

    const onTabChange = activeKey => {
        setActiveKey(activeKey)
        if(activeKey === 'check') {
            showActiveCheck(lesson.id)
        } else if(activeKey === 'statistics') {
            console.log('refresh statistics ')
        }
    }

    const breadcrumbs = [{
        title: (
            <Link to="/lesson">
                <ReadOutlined />&nbsp; Уроки
            </Link>
        )
    }]

    if(lesson) {
        breadcrumbs.push({
            title: lesson.lessonGroup.ru
        })
        breadcrumbs.push({
            title: lesson.nameRu
        })
    }

    return (
        <Container>
            <Breadcrumb items={breadcrumbs} />
            <div style={{background: 'teal'}}>
                width: {screenSize.width}
                <br />
                height: {screenSize.height}
            </div>
            {notificationContextHolder}
            {
                lesson ?
                (
                    <Tabs
                        items={tabItems}
                        activeKey={activeKey}
                        onChange={onTabChange}
                        centered={screenSize.width >= SM}
                    />
                ) : (
                    <Result
                        icon={<CloseCircleTwoTone twoToneColor={token.Icon.bulbColor} />}
                        title="Урок не найден"
                        status="warn"
                    />
                )
            }
        </Container>
    )
}