import axios from 'axios'
import { useReducer } from 'react'
import { AuthContext } from './authContext'
import { authReducer } from './authReducer'
import { SET_SENDING, UNSET_SENDING, LOGIN } from '../types'
import { notification } from 'antd'
import { useNavigate } from 'react-router-dom'
import TokenHelper from '../../helper/TokenHelper'
import TokenService from '../../service/TokenService'
import { LOGIN_ERRORS } from '../../constant/LoginErrors'

const instance = axios.create({
    baseURL: '/services/api'
})

export const AuthState = ({children}) => {

    const navigate = useNavigate()
    let refreshTokenRequest = null
    const [notificationApi, notificationContextHolder] = notification.useNotification()

    const restoreVault = () => {
        const data = localStorage.getItem('USER')
        if(data) {
            try {
                const json = JSON.parse(data)
                const accessToken = TokenHelper.parseJwt(json.accessToken)
                if(TokenHelper.isTokenExpired(accessToken)) {
                    return null
                }
                return accessToken
            } catch(e) {
                return null
            }
        }
        return null
    }

    const initialState = {
        sending: false,
        vault: restoreVault()
    }

    const setSending = () => dispatch({type: SET_SENDING})
    const unsetSending = () => dispatch({type: UNSET_SENDING})
    const setAuth = (accessToken) => dispatch({type: LOGIN, payload: accessToken}) 

    const login = async data => {
        setSending()
        axios.put('/services/open-api/account/login', data)
            .then(loginSuccessHandler)
            .catch(loginErrorHandler)
    }

    const externalLoginSuccessHandler = res => {
        TokenService.setUser(res.data)
        setAuth(TokenHelper.parseJwt(res.data.accessToken))
        navigate('/')
    }

    const loginSuccessHandler = res => {
        console.log('successHandler')
        if(res.data.code === 'SUCCESS') {
            TokenService.setUser(res.data)
            setAuth(TokenHelper.parseJwt(res.data.accessToken))
            navigate('/')
        } else {
            let errorMessage
            let type
            let loginError = LOGIN_ERRORS[res.data.code]
            if(loginError) {
                errorMessage = loginError.name
                type = loginError.type
            } else {
                errorMessage = 'Попробуйте позднее...'
                type = 'error'
            }
            notificationApi[type]({
                message: 'Ошибка входа',
                description: errorMessage,
                placement: 'top'
            })
        }
        unsetSending()
    }

    const loginErrorHandler = error => {
        notificationApi['error']({
            message: 'Ошибка входа ',
            description: error + '\nПопробуйте позднее...',
            placement: 'top'
          })
        unsetSending()
    }

    const [state, dispatch] = useReducer(authReducer, initialState)
    const {sending, vault} = state

    instance.interceptors.request.use(
        (config) => {
            if(config.method === 'delete' &&
                config.url.startsWith('/logout')) {
                TokenService.removeUser()
                setAuth(null)
                return Promise.reject(true)
            }
            const token = TokenService.getAccessToken()
            if(token) {
                config.headers['Authorization'] = `Bearer ${token}`
            }
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )
    
    instance.interceptors.response.use(
        res => res,
        async(err) => {
            const originalConfig = err.config
            if(err.response && err.response.status === 401) {
                try {
                    if(refreshTokenRequest === null) {
                        refreshTokenRequest = axios.post('/services/open-api/account/refresh', {
                            refreshToken: TokenService.getRefreshToken()
                        })
                    }
                    const resp = await refreshTokenRequest
                    refreshTokenRequest = null
                    if(resp.data.code !== 'SUCCESS') {
                        navigate('/login')
                        return Promise.reject(resp.data.code)
                    }
                    TokenService.setUser(resp.data)
                    setAuth(TokenHelper.parseJwt(resp.data.accessToken))
                    return instance(originalConfig)
                } catch (_error) {
                    console.log('ERROR', _error)
                    return Promise.reject(_error)
                }
            }
            return Promise.reject(err)
        }
    )  

    return (
        <AuthContext.Provider value={{login, sending, vault, externalLoginSuccessHandler}}>
            { notificationContextHolder }
            { children }
        </AuthContext.Provider>
    )
}

export default instance
