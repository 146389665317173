import { Row, Col, theme } from 'antd'

export const PrivacyPolicy = () => {
    const {token} = theme.useToken()
    return (
        <Row style={{paddingTop: 20, color: token.Text.color}}>
            <Col span={12} offset={6}>
                <div align="center">
                    <h3>Политика конфиденциальности</h3>
                </div>
                <p>Автор сайта серьезно относится к конфиденциальности Ваших данных.</p>
                <p>Получаем от вас (или с вашего разрешения) минимально необходимую информацию и храним ее для целей аутентификации на сайте:</p>
                <ul>
                    <li>Логин</li>
                    <li>e-mail</li>
                </ul>
                <p>Сайт не запрашивает и не хранит ваши персональные, такие как фамилия и имя.</p>
                <p>Сайт не сохраняет ваш IP-адрес.</p>
                <p>В целях вашего обучения вам предлагается озвучивать фразы, полученные аудио записи обрабатываются, оцениваются и могут сохраняться на сайте некоторое время в целях улучшения алгоритмов оценки.</p>
                <p>Озвучивание фраз не является обязательным, вы можете не пользоваться этой возможностью сайта.</p>
                <p>Для оценки посещаемости веб-сайтов и анализа поведения пользователей используется сторонний сервис <a href="https://yandex.kz/legal/metrica_termsofuse/" target="_blank" rel="noreferrer">Яндекс.Метрика</a> </p>
            </Col>
        </Row>
    )
}