import { Button, Dropdown, Space } from 'antd'
import { CheckSquareOutlined, CheckCircleOutlined, CommentOutlined, DownOutlined, ExpandAltOutlined, FileWordOutlined, FontColorsOutlined } from '@ant-design/icons'
import { useContext } from 'react'
import { AdminExerciseContext } from '../../context/adminExercise/adminExerciseContext'

export const AdminExerciseDrowdown = () => {
    const {setModalOpen, setExercise} = useContext(AdminExerciseContext)

    const exerciseTypeMenuItems = [{
        key: 'DIALOG',
        label: (
            <a
                onClick={() => addExercise('DIALOG')}
                href="#addDialog"
            >
                <Space>
                    <CommentOutlined />
                    Подстановка в диалог
                </Space>
            </a>
        )
    }, {
        key: 'WORD',
        label: (
            <a
                onClick={() => addExercise('WORD')}
                href="#addWord"
            >
                <Space>
                    <FileWordOutlined />
                    Подстановка в слово
                </Space>
            </a>
        )
    }, {
        key: 'TEST_TASK',
        label: (
            <Space>
                <CheckSquareOutlined />
                Тестовое задание
            </Space>
        ),
        children: [{
            key: 'SINGLE_CHOICE',
            label: (
                <a
                    onClick={() => addExercise('TEST_TASK', 'SINGLE_CHOICE')}
                    href="#addSingleChoice"
                >
                    <Space>
                        <CheckCircleOutlined />
                        Единственный выбор
                    </Space>
                </a>
            )
        }, {
            key: 'MULTIPLE_CHOICE',
            label: (
                <a
                    onClick={() => addExercise('TEST_TASK', 'MULTIPLE_CHOICE')}
                    href="#addMultipleChoice"
                >
                    <Space>
                        <CheckSquareOutlined />
                        Множественный выбор
                    </Space>
                </a>
            )
        }, {
            key: 'ARBITRARY_VALUE',
            label: (
                <a
                    onClick={() => addExercise('TEST_TASK', 'ARBITRARY_VALUE')}
                    href="#addArbitraryValue"
                >
                    <Space>
                        <FontColorsOutlined />
                        Произвольное значение
                    </Space>
                </a>
            )
        }]
    }, {
        key: 'ORDER',
        label: (
            <a
                onClick={() => addExercise('ORDER')}
                href="#addOrder"
            >
                <Space>
                    <ExpandAltOutlined />
                    Упорядочивание элементов в диалоге
                </Space>
            </a>
        )
    }]

    const addExercise = (exerciseType, taskType) => {
        console.log('addExercise', exerciseType, taskType)
        if(taskType) {
            setExercise({
                exerciseType: exerciseType,
                testTask: {
                    taskType: taskType
                }
            })
        } else {
            setExercise({
                exerciseType: exerciseType
            })
        }
        setModalOpen(true)
    }

    return (
        <Dropdown
            menu={{items: exerciseTypeMenuItems}}
        >
            <Button>
                <Space>
                    Добавить упражнение
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    )
}