import { Result, Col, Row, Button } from 'antd'
import { useContext, useEffect } from 'react'
import { useSearchParams, Link }from 'react-router-dom'
import { ActivationContext } from '../context/activation/activationContext'
import { LoadingOutlined, LoginOutlined } from '@ant-design/icons'

export const Activation = () => {
    const {activate, sending, flagSuccess, flagError} = useContext(ActivationContext)
    const [searchParams] = useSearchParams();

    useEffect(() => {
        activate(searchParams.get('key'))
        // eslint-disable-next-line
    }, [])

    return (
        <Row style={{paddingTop: 20}}>
            <Col span={12} offset={6}>
                { 
                    sending ? 
                    <Result
                        icon={<LoadingOutlined />}
                        title="Идет активация..."
                    /> : null
                }
                {
                    flagSuccess ? 
                    <Result
                        status="success"
                        title="Учетная запись активирована"
                        extra={
                            <Button 
                                key='login'
                                type="primary"
                            >
                              <LoginOutlined /> <Link to={'/login'}>Войти</Link>
                            </Button>
                          }
                    /> : null
                }
                {
                    flagError ?
                    <Result
                        status="warning"
                        title="Произошла ошибка активации"
                    /> : null
                }
            </Col>
        </Row>
    )
}