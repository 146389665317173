const handlers = {
    SET_SENDING: state => ({...state, sending: true}),
    ACTIVATION_SUCCESS: state => ({...state, sending: false, flagSuccess: true, flagError: false}),
    ACTIVATION_ERROR: state => ({...state, sending: false, flagSuccess: false, flagError: true}),
    DEFAULT: state => state
}

export const activationReducer = (state, action) => {
    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}