class TokenHelper {

    static parseJwt = token => {
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        const jsonPayload = decodeURIComponent(
          window
            .atob(base64)
            .split('')
            .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join('')
        )
        return JSON.parse(jsonPayload)
    }

    static isTokenExpired = token => {
        const expDate = new Date(token.exp * 1000)
        const now = new Date()
        return expDate < now
    }

}

export default TokenHelper