import axios from 'axios'
import { useEffect, useContext } from 'react'
import { useSearchParams }from 'react-router-dom'
import { AuthContext } from '../context/auth/authContext'
import { GoogleAuthContext } from '../context/auth/google/googleAuthContext'
import { Alert, Button, Col, Row, Spin } from 'antd'
import { Link } from 'react-router-dom'
import { REGISTRATION_ERRORS } from '../constant/RegistrationErrors'
import { LOGIN_ERRORS } from '../constant/LoginErrors'
import ErrorHelper from '../helper/ErrorHelper'

export const GoogleAuthCallback = () => {

    const [searchParams] = useSearchParams();
    const {externalLoginSuccessHandler} = useContext(AuthContext)
    const {setChecking, checking, result, authSuccess, authError, error} = useContext(GoogleAuthContext)

    useEffect(() => {
        const data = {code: searchParams.get('code')}
        setChecking()
        axios.put('/services/open-api/google/auth/callback', data)
            .then(res => {
                externalLoginSuccessHandler(res)
                authSuccess()
            })
            .catch(err => {
                const errorCode = err.response.data.code;
                const errorInfo = ErrorHelper.getError(errorCode, REGISTRATION_ERRORS, LOGIN_ERRORS)
                if(errorInfo) {
                    authError({message: 'Ошибка аутентификации. Попробуйте еще раз', type: errorInfo.type, description: errorInfo.name})
                } else {
                    authError({message: 'Ошибка аутентификации. Попробуйте еще раз', type: 'error', description: ''})
                }
            })
        // eslint-disable-next-line
    }, [])

    const checkingFragment = checking ?
        <Spin tip="Идет проверка аутентификации..."/> : null

    let resultFragment = ''

    if(result === 'SUCCESS') {
        resultFragment = 
            <Alert 
                message="Аутентификация прошла успешно!" 
                type="success" 
                showIcon />
    } else if(result === 'ERROR') {
        resultFragment = 
            <Alert 
                message={error.message} 
                description={error.description}
                type={error.type} 
                action={
                    <Button>
                        <Link to={'/login'}>Войти</Link>
                    </Button>
                }
                showIcon />
    }

    return (
        <Row style={{paddingTop: 20}}>
            <Col span={12} offset={6}>
                <div align="center">
                    { checkingFragment }
                    { resultFragment }
                </div>
            </Col>
        </Row>
    )
}