class ExerciseService {

    getDescription(exercise) {
        switch(exercise.exerciseType) {
            case 'TEST_TASK':
                switch(exercise.testTask.taskType) {
                    case 'ARBITRARY_VALUE':
                        return exercise.testTask.content
                    case 'SINGLE_CHOICE':
                        return exercise.testTask.content
                    case 'MULTIPLE_CHOICE':
                        return exercise.testTask.content
                    default:
                        return ''
                }
            case 'DIALOG':
                return (
                    <>
                        {exercise.questionKk}
                        <br/ >
                        {exercise.answerKk}
                    </>
                )
            case 'WORD':
                return exercise.answerKk
            case 'ORDER':
                return (
                    <>
                        {exercise.questionKk}
                        <br/ >
                        {exercise.answerKk}
                    </>
                )
            default:
                return 'Unknown Type'
        }
    }

}

export default new ExerciseService()