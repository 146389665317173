import { Form, Space } from 'antd'
import { InputField } from '../component/InputField'

export default function substitutionRender(fieldPrefix, value, maxSymbolCount) {
    const formItems = []
    const buf = []
    let fieldNumber = 0
    for(let i = 0; i < value.length; ++i) {
        if(value[i] === '[') {
            join(formItems, buf)
            buf.length = 0
            ++fieldNumber
            formItems.push((
                <Form.Item name={fieldPrefix + fieldNumber} style={{marginBottom: "0px"}}>
                    <InputField maxSymbolCount={maxSymbolCount} />
                </Form.Item>
            ))
        } else if(value[i] !== ']'){
            buf.push(value[i])
        }
    }
    if(buf.length) {
        join(formItems, buf)
    }
    return (
        <Space.Compact>
            { formItems }
        </Space.Compact>
    )
}

function join(formItems, buf) {
    if(buf[0] === ' ') {
        formItems.push(<>&nbsp;</>)
    }
    formItems.push((
        <Form.Item style={{marginBottom: "0px"}}>
            {
                buf.join('')
            }
        </Form.Item>
    ))
    if(buf[buf.length - 1] === ' ') {
        formItems.push(<>&nbsp;</>)
    }
}