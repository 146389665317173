import { useContext } from 'react'
import { Menu, theme } from 'antd'
import { Link, useLocation }from 'react-router-dom'
import { UserOutlined, ReadOutlined, BookOutlined, HomeOutlined, CommentOutlined, CarryOutOutlined, LoginOutlined, ExperimentOutlined, ToolOutlined } from '@ant-design/icons'
import { AuthContext } from '../context/auth/authContext'
import FeatureFlagService from '../service/FeatureFlagService'

export const Navbar = () => {
    const location = useLocation()
    const {token} = theme.useToken()
    let currentKey = location.pathname.replace('/', '')
    if(currentKey === '') {
        currentKey = 'home'
    }
    const logo = (
        <span>
            <span style={{color: token.Logo.firstColor}}>Salemet</span>
            <span style={{color: token.Logo.secondColor}}>siz.</span>
            <span style={{color: token.Logo.firstColor}}>be</span>
        </span>
    )

    const {vault} = useContext(AuthContext)
    let authenticated = vault && vault.sub
    let hasAdminRole = vault && vault.roles && vault.roles.includes('ROLE_ADMIN')

    const items = [
        {key: 'home', icon: <HomeOutlined />, label: (<Link to='/'>{logo}</Link>)},
        {key: 'lesson', icon: <ReadOutlined />, label: (<Link to='/lesson'>Уроки</Link>)},
        {key: 'exercise', icon: <CarryOutOutlined />, label: (<Link to='/exercise'>Упражнения</Link>)},
        {key: 'dialog', icon: <CommentOutlined />, label: (<Link to='/dialog'>Диалоги</Link>)},
        {key: 'dictionary', icon: <BookOutlined />, label: (<Link to='/dictionary'>Словарь</Link>)}
    ]

    if(FeatureFlagService.showTestPage()) {
        items.push({key: 'testPage', icon: <ExperimentOutlined />, label: (<Link to='/testPage'>Тест</Link>)})
    }
    if(hasAdminRole) {
        items.push({key: 'admin', icon: <ToolOutlined />, label: (<Link to='/admin'>Админка</Link>)})
    }
    
    if(authenticated) {
        items.push({key: 'profile', icon: <UserOutlined />, label: (<Link to='/profile'>Профиль</Link>)})
    } else {
        items.push({key: 'login', icon: <LoginOutlined />, label: (<Link to='/login'>Войти</Link>)})
    }

    return (
        <Menu 
            mode="inline" 
            style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center'
            }}
            items={items}
            selectedKeys={[currentKey]}
        />
    )
}