import { Checkbox, Divider, Form, Input, notification } from 'antd'
import { useContext, useEffect } from 'react'
import { TEST_TASK_TYPES } from '../../constant/TestTaskTypes'
import { AdminExerciseContext } from '../../context/adminExercise/adminExerciseContext'
import { AdminExerciseTestTaskArbitraryValueFormFragment } from './AdminExerciseTestTaskArbitraryValueFormFragment'
import { AdminExerciseTestTaskMultipleChoiceFormFragment } from './AdminExerciseTestTaskMultipleChoiceFormFragment'
import { AdminExerciseTestTaskSingleChoiceFormFragment } from './AdminExerciseTestTaskSingleChoiceFormFragment'
import api from '../../context/auth/AuthState'
import showErrorNotification from '../../helper/showErrorNotification'
import showCertainErrorNotification from '../../helper/showCertainErrorNotification'
import showSavedNotification from '../../helper/showSavedNotification'

export const AdminExerciseTestTaskForm = ({updateLessonExercises}) => {
    const {exercise, setModalOpen, lesson} = useContext(AdminExerciseContext)
    const [form] = Form.useForm()
    const [notificationApi, notificationContextHolder] = notification.useNotification()

    useEffect(() => {
        console.log('exercise', exercise)
        if(exercise?.id) {
            api.get(`/admin/test-task/${exercise.id}`)
            .then(response => {
                form.resetFields()
                form.setFieldsValue(response.data)
            })
            .catch(e => {
                showErrorNotification(notificationApi, e)
            })
        } else {
            if(!exercise?.testTask) {
                return
            }
            form.resetFields()
            form.setFieldsValue({
                exerciseType: 'TEST_TASK',
                testTask: {
                    taskType: exercise.testTask.taskType,
                    deleted: false
                },
                lesson: lesson,
                deleted: false
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exercise])

    const saveTestTask = values => {
        console.log('saveTestTask', values)
        if(values.testTask.taskType === 'ARBITRARY_VALUE') {
            const testTaskValues = values.testTask.values.filter(row => row)
            testTaskValues.forEach(row => row.deleted = false)
            values.testTask.values = testTaskValues
        } else if(values.testTask.taskType === 'MULTIPLE_CHOICE'
            || values.testTask.taskType === 'SINGLE_CHOICE') {
            if(!values.testTask.items) {
                values.testTask.items = []
            }
            const testTaskItems = values.testTask.items.filter(row => row)
            testTaskItems.forEach(row => row.flagCorrect = row.flagCorrect ? true : false)
            testTaskItems.forEach(row => row.deleted = false)
            if(!validate(values.testTask.taskType, testTaskItems)) {
                return
            }
            values.testTask.items = testTaskItems
        }

        api.put('/admin/test-task', values).then(() => {
            showSavedNotification(notificationApi)
            setModalOpen(false)
            updateLessonExercises(lesson.id)
        }).catch(e => {
            showErrorNotification(notificationApi, e)
        })
    }

    const validate = (taskType, testTaskItems) => {
        if(!testTaskItems) {
            showCertainErrorNotification(notificationApi, 'Не указаны варианты')
            return false
        }
        if(testTaskItems.length < 2) {
            showCertainErrorNotification(notificationApi, 'Недостаточно вариантов')
            return false
        }
        if(!testTaskItems.some(row => row.flagCorrect)) {
            showCertainErrorNotification(notificationApi, 'Не выбран правильный вариант(ы)')
            return false
        }
        if(taskType === 'SINGLE_CHOICE'
            && testTaskItems.filter(row => row.flagCorrect).length > 1) {
            showCertainErrorNotification(notificationApi, 'Должен быть только один правильный вариант')
            return false
        }
        return true
    }

    if(!exercise?.testTask) {
        return (
            <></>
        )
    }

    return (
        <>
            {notificationContextHolder}
            <Divider>
                Тестовое задание / {TEST_TASK_TYPES[exercise.testTask.taskType]}
            </Divider>
            <Form
                id="admin-exercise-form"
                name="control-ref"
                form={form}
                onFinish={saveTestTask}
            >
                <Form.Item
                    name="id"
                    hidden
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={['lesson', 'id']}
                    hidden
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={['testTask', 'taskType']}
                    hidden
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={['testTask', 'deleted']}
                    valuePropName="checked"
                    hidden
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    name="exerciseType"
                    hidden
                >
                    <Input />
                </Form.Item>
                {
                    exercise.testTask.taskType === 'ARBITRARY_VALUE'
                        ? <AdminExerciseTestTaskArbitraryValueFormFragment form={form} />
                        : null
                }
                {
                    exercise.testTask.taskType === 'MULTIPLE_CHOICE'
                    ? <AdminExerciseTestTaskMultipleChoiceFormFragment />
                    : null
                }
                {
                    exercise.testTask.taskType === 'SINGLE_CHOICE'
                    ? <AdminExerciseTestTaskSingleChoiceFormFragment form={form} />
                    : null
                }
                <Form.Item
                    name="deleted"
                    valuePropName="checked"
                >
                    <Checkbox>
                        Удалено
                    </Checkbox>
                </Form.Item>
            </Form>
        </>
    )
}