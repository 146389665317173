class DictionaryService {

    getExerciseTypeValue(exerciseType, dialogType) {
        switch(exerciseType) {
            case 'DIALOG':
                if(!dialogType || dialogType === 'DIALOG') {
                    return 'Подстановка в диалог'
                } else {
                    return 'Подстановка во фразу'
                }
            case 'WORD':
                return 'Подстановка в слово'
            case 'TEST_TASK':
                return 'Тестовое задание'
            case 'ORDER':
                if(!dialogType || dialogType === 'DIALOG') {
                    return 'Упорядочивание элементов в диалоге'
                } else {
                    return 'Упорядочивание элементов во фразе'
                }
            default:
                return 'Unknown Exercise Type'
        }
    }

    getTestTaskTypeValue(taskType) {
        switch(taskType) {
            case 'SINGLE_CHOICE':
                return 'Единственный выбор'
            case 'MULTIPLE_CHOICE':
                return 'Множественный выбор'
            case 'ARBITRARY_VALUE':
                return 'Произвольное значение'
            default:
                return null
        }
    }

}

export default new DictionaryService()