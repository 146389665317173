import axios from 'axios'
import { useReducer } from 'react'
import { RegisterContext } from './registerContext'
import { registerReducer } from './registerReducer'
import { SET_SENDING, REGISTER_SUCCESS, REGISTER_ERROR } from '../types'
import { REGISTRATION_ERRORS } from '../../constant/RegistrationErrors'

export const RegisterState = ({children}) => {
    const initialState = {
        sending: false,
        flagSuccess: false,
        flagError: false,
        flagForm: true,
        errorMessage: '',
        errorType: 'warning'
    }

    const [state, dispatch] = useReducer(registerReducer, initialState)

    const register = async data => {
        setSending()
        axios.post('/services/open-api/registration/register', data.user)
            .then(() => {
                registerSuccess()
            })
            .catch(err => {
                let regError = REGISTRATION_ERRORS[err.response.data.code]
                if(regError) {
                    registerError(regError.name, regError.type)
                } else {
                    registerError('Ошибка регистрации. Попробуйте еще раз.', 'error')
                }
            })
    }

    const setSending = () => dispatch({type: SET_SENDING})
    const registerSuccess = () => dispatch({type: REGISTER_SUCCESS})
    const registerError = (errorMessage, errorType) => dispatch({type: REGISTER_ERROR, errorMessage: errorMessage, errorType: errorType})

    const {flagForm, flagSuccess, flagError, errorMessage, errorType, sending} = state

    return (
        <RegisterContext.Provider value={{register, flagForm, flagSuccess, flagError, errorMessage, errorType, sending}}>
            { children }
        </RegisterContext.Provider>
    )
}