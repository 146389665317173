import { useReducer } from 'react'
import { AUTH_ERROR, AUTH_SUCCESS, SET_CHECKING } from '../../types'
import { GoogleAuthContext } from './googleAuthContext'
import { googleAuthReducer } from './googleAuthReducer'

export const GoogleAuthState = ({children}) => {
    const initialState = {
        checking: false,
        result: '',
        error: {}
    }

    const setChecking = () => dispatch({type: SET_CHECKING})
    const authSuccess = () => dispatch({type: AUTH_SUCCESS})
    const authError = (error) => dispatch({type: AUTH_ERROR, error: error})

    const [state, dispatch] = useReducer(googleAuthReducer, initialState)
    const {checking, result, error} = state

    return (
        <GoogleAuthContext.Provider value={{setChecking, authSuccess, authError, checking, result, error}}>
            { children }
        </GoogleAuthContext.Provider>
    )
}