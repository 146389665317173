import { useContext, useEffect } from 'react'
import { UserPrefContext } from '../context/userPref/userPrefContext'
import updateTheme from '../helper/updateTheme'

export const ThemeModeInterval = () => {
    const {darkThemeMode, setTheme} = useContext(UserPrefContext)

    useEffect(() => {
        setInterval(updateTheme(darkThemeMode, setTheme), 5000)
        // eslint-disable-next-line
    }, [])

    return (
        <></>
    )
}